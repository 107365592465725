import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from 'src/environments/environment';

import { Usuarios, LoginForm, SolicitarReseteoForm, ResetearContrasenaForm } from '../models';
@Injectable()
export class SiteService {
    private helper = new JwtHelperService();

    constructor(private _httpService: HttpClient, private router: Router) {}

    aceptarInvitacion(form: ResetearContrasenaForm, codigo: String) {
        return this._httpService.post<string>(environment.WEB_API_URL + 'site/aceptar-invitacion', {
            ReseteoContrasena: form,
            codigo: codigo,
        });
    }

    verificarInvitacion(codigo: String): Observable<boolean> {
        return this._httpService.post<boolean>(environment.WEB_API_URL + 'site/verificar-invitacion', { codigo: codigo });
    }

    registrar(usuario: Usuarios): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'site/registrar', { Registro: usuario }, this.jwt());
    }

    login(usuario: LoginForm) {
        return this._httpService
            .post<any>(environment.WEB_API_URL + 'site/login', { Login: usuario })
            .pipe(
                map((response) => {
                    const token = response.token;
                    const codigo = response.codigo;

                    if (codigo) {
                        this.router.navigateByUrl('/resetear-contrasena/' + codigo);
                    } else {
                        const decodedToken = this.helper.decodeToken(token);

                        localStorage.setItem('token', token);
                        localStorage.setItem('idUsuario', decodedToken.id);
                        localStorage.setItem('nombreUsuario', decodedToken.nombre_usuario);

                        this.router.navigateByUrl('/empresas');
                    }
                })
            );
    }

    logOut() {
        localStorage.clear();
        this.router.navigateByUrl('/login');
    }

    solicitarReseteo(email: string) {
        return this._httpService.post<string>(environment.WEB_API_URL + 'site/solicitar-reseteo', email);
    }

    resetear(resetearContrasena: ResetearContrasenaForm) {
        return this._httpService.post<string>(environment.WEB_API_URL + 'site/resetear', { ReseteoContrasena: resetearContrasena });
    }

    private jwt() {
        const token = localStorage.getItem('token');
        if (token) {
            const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
            return { headers: headers };
        }
    }
}
