<div fxFlexFill fxLayout="column" fxLayoutAlign="space-around center">
    <div fxLayoutAlign.lt-md="center center">
        <mat-card fxFlex fxFlex.lt-md="95" fxLayout="column" fxLayoutAlign="start center" class="verificar-card">
            <mat-card-header>
                <mat-card-title style="font-size: -webkit-xxx-large; color: grey"> ¡YA CASI! </mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <h3>Se envió un correo a la dirección indicada con instrucciones para continuar.</h3>
            </mat-card-content>

            <button mat-flat-button type="submit" color="primary" [routerLink]="['/login']">Acceder</button>
        </mat-card>
    </div>

    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />
</div>
