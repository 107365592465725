import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Documentos } from 'src/app/models/documentos';
import { DocumentosService, EventEmitterService } from 'src/app/services';
import { DialogComponent } from 'src/app/shared/components';
import { DialogConfig, DialogData } from 'src/app/shared/models/index';

@Component({
    selector: 'app-archivos-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss'],
})
export class ArchivosBottomSheetComponent implements OnInit {
    documento: Documentos;

    eliminandoDocumentoId = undefined;
    procesandoDocumento = false;

    constructor(
        private _documentosService: DocumentosService,
        private _snackBar: MatSnackBar,
        private _eventEmitter: EventEmitterService,
        private _dialog: MatDialog,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.documento = data;
    }

    ngOnInit() {}

    abrirDocumento(documento: Documentos): void {
        this._documentosService.abrir(documento.id).subscribe(
            (response) => {
                this._snackBar.open('Es posible que sea necesario habilitar los pop-ups', 'OK', {
                    duration: 6000,
                    panelClass: ['info-snackbar'],
                });

                const blob = new Blob([response], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            },
            (error) => this._snackBar.open('Error al abrir el archivo', 'OK', { duration: 6000, panelClass: ['error-snackbar'] })
        );
    }

    reprocesar() {
        this.procesandoDocumento = true;
        this._documentosService.reprocesar(this.documento.id).subscribe(
            (response: any) => {
                this.procesandoDocumento = false;
                this._snackBar.open(`Se asignó a ${response.funcionarios.length} funcionarios.`, 'OK', {
                    duration: 6000,
                    panelClass: ['success-snackbar'],
                });

                if (response.funcionarios.length) {
                    this._eventEmitter.actualizarListadoDocumentos('reload');
                    this._eventEmitter.actualizarListadoFuncionarios('reload');
                }
            },
            (error) => {
                this.procesandoDocumento = false;
                this._snackBar.open('Error al procesar documento', 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }

    eliminar(documento?: Documentos) {
        const dialogData = new DialogData();
        const dialogConfig = new DialogConfig({
            ...dialogData,
            titulo: '¿Eliminar documento?',
            contenido: 'Los funcionarios ya no podrán acceder a su documento.',
        });

        this._dialog
            .open(DialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((resultado) => {
                if (resultado) {
                    const documentoEliminar = documento ?? this.documento;
                    this.eliminandoDocumentoId = documentoEliminar.id;

                    this._documentosService.eliminar(documentoEliminar.id).subscribe(
                        (response) => {
                            this._snackBar.open('Documento eliminado', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });
                            this._eventEmitter.actualizarListadoDocumentos('reload');
                            this._eventEmitter.actualizarListadoFuncionarios('reload');
                            this.eliminandoDocumentoId = undefined;
                        },
                        (error) => {
                            this._snackBar.open('Error al eliminar documento', 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
                            this.eliminandoDocumentoId = undefined;
                        }
                    );
                }
            });
    }

    get esIndividual() {
        return !Array.isArray(this.documento.usuario0);
    }
}
