import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ValidadorRut } from 'src/app/shared/index';

import { EmpresasService } from 'src/app/services/empresas.service';
@Component({
    selector: 'app-empresas-registrar',
    templateUrl: './registrar.component.html',
    styleUrls: ['./registrar.component.scss'],
})
export class RegistrarComponent implements OnInit {
    @Output() actualizarListado = new EventEmitter();

    registroForm: FormGroup;
    form: FormGroup;

    mensajeError = '';

    submitted = false;
    mostrarSpinner = false;
    mostrarRegistroEmpresa = false;

    constructor(private _empresasService: EmpresasService, private formBuilder: FormBuilder, private snackBar: MatSnackBar) {}

    ngOnInit() {
        this.initForm();
    }

    onChange(event) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            this.form.get('file').setValue(file);
        }
    }

    crearEmpresa() {
        if (this.registroForm.invalid) {
            return;
        }

        this.submitted = true;
        this.mostrarSpinner = true;

        const formData: FormData = new FormData();

        if (this.form.value.file) {
            formData.append('file', this.form.value.file, this.form.get('file').value.name);
        }

        formData.append('nombre', this.registroForm.get('nombre').value);
        formData.append('razon_social', this.registroForm.get('razon_social').value);
        formData.append('rut', this.registroForm.get('rut').value);

        this._empresasService.registrar(formData).subscribe(
            (response) => {
                this.mostrarSpinner = false;
                this.mostrarRegistroEmpresa = false;

                this.initForm();
                this.actualizarListado.emit('reload');
            },
            (error: any) => {
                this.mostrarSpinner = false;
                const msj = error.error?.message || 'Error al crear empresa';
                this.snackBar.open(msj, 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }

    initForm() {
        this.registroForm = this.formBuilder.group(
            {
                razon_social: ['', Validators.required],
                nombre: '',
                rut: ['', Validators.required],
                archivo: '',
            },
            { validator: ValidadorRut() }
        );

        this.form = this.formBuilder.group({ file: [''] });

        this.mensajeError = '';
        this.mostrarRegistroEmpresa = false;
    }

    get f() {
        return this.registroForm.controls;
    }
}
