import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    texto: string;
    restablecerContrasena: boolean;
    ocultarCelular: boolean;
    ocultarEmail: boolean;
}
@Component({
    selector: 'app-funcionario-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class FuncionarioDialogComponent implements OnInit {
    restablecerContrasena: boolean;
    ocultarCelular: boolean;
    ocultarEmail: boolean;

    celular = true;
    email = true;

    constructor(public dialogRef: MatDialogRef<FuncionarioDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    ngOnInit() {
        this.restablecerContrasena = this.data.restablecerContrasena;
        this.ocultarCelular = this.data.ocultarCelular;
        this.ocultarEmail = this.data.ocultarEmail;
    }

    enviar() {
        this.dialogRef.close({ celular: this.celular, email: this.email });
    }

    cancelar(): void {
        this.dialogRef.close();
    }
}
