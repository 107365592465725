<div fxFlexFill fxLayout="column" fxLayoutGap="15px">
    <a (click)="abrirDocumento(documento)" class="nombre-archivo" fxLayoutAlign=" end" fxLayoutGap="5px">
        <span>{{ documento.archivo0.nombre_origen }}</span>
        <mat-icon>open_in_new</mat-icon>
    </a>

    <mat-nav-list *ngIf="documento.documentos.length">
        <div fxLayoutAlign="space-between center" fxLayoutGap="5px" *ngFor="let documento of documento.documentos">
            <a (click)="abrirDocumento(documento)" mat-list-item (click)="openLink($event)">
                <span mat-line>{{ documento.usuario0.nombre }}</span>
                <span mat-line>Fecha carga: {{ documento.fecha_creacion }}</span>
                <span mat-line>Fecha visto: {{ documento.fecha_aceptado || 'Pendiente' }}</span>
            </a>
            <div fxLayoutAlign="center center" fxFlex="10">
                <button mat-icon-button matTooltip="Eliminar" (click)="eliminar(documento)" *ngIf="eliminandoDocumentoId != documento.id">
                    <mat-icon>delete</mat-icon>
                </button>

                <mat-spinner *ngIf="eliminandoDocumentoId == documento.id" [diameter]="25"></mat-spinner>
            </div>
        </div>
    </mat-nav-list>

    <div *ngIf="!documento.documentos.length && esIndividual" fxLayout="column" fxLayoutAlign="start stretch">
        <h4>Carga individual a {{ documento.usuario0.nombre }}</h4>
        <span>Fecha carga: {{ documento.fecha_creacion.replace(' ', 'T') | date: 'd/M/Y, H:mm' }}</span>
        <span>Fecha visto: {{ documento.fecha_aceptado ? (documento.fecha_aceptado.replace(' ', 'T') | date: 'd/M/Y, H:mm') : 'Pendiente' }}</span>
    </div>

    <p *ngIf="!documento.documentos.length && !esIndividual" class="no-hay-resultados">El documento no fue asociado a ningún funcionario</p>

    <div fxLayoutAlign="center center" fxLayoutGap="5px">
        <button mat-flat-button color="primary" (click)="reprocesar()" [disabled]="procesandoDocumento">
            Procesar nuevamente
            <mat-icon *ngIf="procesandoDocumento">
                <mat-spinner color="primary" diameter="20"></mat-spinner>
            </mat-icon>
        </button>

        <button mat-flat-button color="warn" (click)="eliminar()" [disabled]="eliminandoDocumentoId == documento.id">
            Eliminar
            <mat-icon *ngIf="eliminandoDocumentoId == documento.id">
                <mat-spinner color="primary" diameter="20"></mat-spinner>
            </mat-icon>
        </button>
    </div>
</div>
