import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PasswordValidation } from 'src/app/shared/index';

import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
    selector: 'resetear-contrasena',
    templateUrl: './resetear-contrasena.component.html',
    styleUrls: ['./resetear-contrasena.component.scss'],
})
export class ResetearContrasenaComponent implements OnInit {
    codigo: String;

    resetearForm: FormGroup;

    mostrarLoader = false;
    submitted = false;
    cargando = true;
    valido = false;

    constructor(
        private formBuilder: FormBuilder,
        private _usuariosService: UsuariosService,
        private route: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.codigo = this.route.snapshot.params['codigo'];

        this.resetearForm = this.formBuilder.group(
            {
                contrasena: ['', Validators.required],
                repetir_contrasena: [''],
                codigo: this.codigo,
            },
            { validator: PasswordValidation.MatchPassword }
        );

        this.validarCodigoReseteo();
    }

    get f() {
        return this.resetearForm.controls;
    }

    resetear() {
        if (this.resetearForm.invalid) {
            return;
        }

        this.submitted = true;
        this.mostrarLoader = true;

        this._usuariosService.resetearContrasena(this.resetearForm.value).subscribe(
            (response) => {
                this.snackBar.open('Cambio de contraseña exitoso', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });
                this.router.navigateByUrl('/login');
            },
            (error) => {
                this.submitted = false;
                this.mostrarLoader = false;
                this.snackBar.open(error.error?.message || 'Error interno del servidor', 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }

    validarCodigoReseteo() {
        this._usuariosService.resetearContrasena(this.resetearForm.value).subscribe(
            (response) => {
                this.valido = true;
                this.cargando = false;
            },
            (error) => {
                this.valido = false;
                this.cargando = false;
            }
        );
    }
}
