<mat-progress-bar *ngIf="subiendo" mode="indeterminate" color="primary"> </mat-progress-bar>

<mat-card [class.subiendo]="subiendo">
    <mat-card-header>
        <mat-card-title>Subir Archivos</mat-card-title>
        <mat-card-subtitle>Tamaño máximo: 500 MB</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content fxLayout="column" fxLayoutAlign="center">
        <div fxLayoutAlign="center center">
            <button mat-button (click)="fileInput.click()" [fxHide]="!hideArchivo">Seleccionar</button>
        </div>

        <div hidden [class.file-uploaded]="form.get('files')?.value">
            <form [formGroup]="form">
                <div class="form-group">
                    <input #fileInput (change)="onChange($event)" type="file" multiple hidden />
                </div>
            </form>
        </div>

        <mat-list fxFill fxLayoutAlign="center start" [fxHide]="hideArchivo">
            <mat-list-item>
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                <h4 mat-line>{{ this.form.get('file').value.name }}</h4>
                <p mat-line>{{ (this.form.get('file').value.size / 1024 / 1024).toFixed(2) }} MB</p>
            </mat-list-item>
        </mat-list>

        <mat-error *ngFor="let mensajeError of mensajesError" [fxHide]="hideArchivo">{{ mensajeError }}</mat-error>
    </mat-card-content>

    <div mat-dialog-actions [fxHide]="hideArchivo" fxLayoutAlign="end" fxLayoutGap="10px">
        <button [disabled]="subiendo" mat-button (click)="cerrarArchivo()">Cancelar</button>
        <button [disabled]="subiendo" mat-flat-button color="primary" (click)="subirArchivo()">Aceptar</button>
    </div>
</mat-card>
