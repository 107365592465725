import { Optional, TemplateRef } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

class BotonDialog {
    texto: string;
    mostrar: boolean = true;
    color: string;
    deshabilitado: boolean = false;

    constructor(texto = 'Aceptar') {
        this.texto = texto;
        this.color = 'primary';
    }
}

class BotonesDialog {
    aceptar = new BotonDialog();
    cancelar = new BotonDialog('Cancelar');
}

export class DialogData {
    titulo?: string = '';
    contenido?: string = '';
    cargando?: boolean = false;
    sinBotones?: boolean = false;
    template?: TemplateRef<any> = null;
    botones? = new BotonesDialog();
}

export class DialogConfig extends MatDialogConfig {
    constructor(@Optional() data: DialogData) {
        super();

        this.data = data;
    }

    width: string = '350px';
    minWidth: string = '400px';
    maxHeight: string = '90vh';
    autoFocus = false;
    restoreFocus = false;
}
