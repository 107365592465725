<div fxFill fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" *ngIf="!cargando && codigoValido">
    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />

    <div fxLayoutAlign="center start">
        <div fxLayout="column" fxFlex="30" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="90">
            <mat-progress-bar *ngIf="mostrarSpinner" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card fxFlex="40" fxFlex.lt-md="95" [class.spinner]="mostrarSpinner">
                <mat-card-header>
                    <mat-card-title>Bienvenido</mat-card-title>
                    <mat-card-subtitle>El último paso es elegir tu contraseña</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="invitacionForm" (ngSubmit)="aceptar()" fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input required #emailIn matInput type="password" formControlName="contrasena" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.contrasena.errors }" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Repetir contraseña</mat-label>
                            <input required #emailIn matInput type="password" formControlName="repetir_contrasena" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.contrasena.errors }" />
                        </mat-form-field>

                        <mat-card-actions fxLayoutAlign="center center" fxLayoutGap="10px">
                            <button mat-raised-button type="button" (click)="aceptarSinContrasena()" [disabled]="mostrarSpinner">Ya tengo contraseña</button>
                            <button mat-flat-button type="submit" color="primary" [disabled]="mostrarSpinner">Crear</button>
                        </mat-card-actions>
                    </form>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-footer fxLayout="column" fxLayoutAlign="start center" class="footer">
                    <p class="textoGris">¿No tenés cuenta?</p>
                    <button mat-flat-button color="link" [routerLink]="['/registro']">Registrate</button>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</div>

<div fxFill fxLayout="column" *ngIf="!cargando && !codigoValido" fxLayoutAlign="start stretch" fxLayoutGap="30px">
    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />

    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-card fxFlex="40" fxFlex.lt-md="95" fxLayout="column" fxLayoutAlign="center center">
            <mat-card-header>
                <mat-card-title style="font-size: -webkit-xxx-large; color: grey">Error</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <p>{{ error ? error : 'Código inválido.' }}</p>
            </mat-card-content>

            <p>Para volver a la página principal:</p>

            <button mat-flat-button type="submit" color="primary" [routerLink]="['/login']">Iniciar sesión</button>
        </mat-card>
    </div>
</div>

<div fxFill fxLayoutAlign="center center">
    <mat-spinner *ngIf="cargando"></mat-spinner>
</div>
