import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SiteService } from 'src/app/services';
import { PasswordValidation } from 'src/app/shared';

@Component({
    selector: 'app-invitacion',
    templateUrl: './invitacion.component.html',
    styleUrls: ['./invitacion.component.scss'],
})
export class InvitacionComponent implements OnInit {
    invitacionForm: FormGroup;

    email: String;
    codigo: String;
    error: String;

    cargando = true;
    mostrarSpinner = false;
    codigoValido = false;

    constructor(
        private _siteService: SiteService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.codigo = this.route.snapshot.queryParams['codigo'];

        this.invitacionForm = this.formBuilder.group(
            {
                contrasena: ['', Validators.required],
                repetir_contrasena: [''],
            },
            { validator: PasswordValidation.MatchPassword }
        );

        this._siteService.verificarInvitacion(this.codigo).subscribe(
            (res: any) => {
                if (res.validado) {
                    this.aceptar();
                }

                this.codigoValido = true;
                this.cargando = false;
            },
            (err) => {
                this.error = err.error?.message || 'El código no es válido';
                this.cargando = false;
            }
        );
    }

    aceptarSinContrasena() {
        this.aceptar(false);
    }

    aceptar(conContrasena = true) {
        this.mostrarSpinner = true;
        this._siteService.aceptarInvitacion(conContrasena ? this.invitacionForm.value : undefined, this.codigo).subscribe(
            (_) => {
                this.router.navigateByUrl('/empresas');
                this.snackBar.open('¡Bienvenido a Sueldos!', 'OK', { duration: 7000, panelClass: ['success-snackbar'] });
            },
            (err) => {
                this.mostrarSpinner = false;
                this.snackBar.open('No se pudo aceptar la invitación', 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }
}
