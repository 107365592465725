import { FormGroup } from '@angular/forms';

export function ValidadorCi(field = 'documento') {
    return (form: FormGroup) => {
        const control = form.controls[field];

        if (!control || !control.value) {
            return null;
        }

        if (!validate_ci(control.value)) {
            return control.setErrors({ largoIncorrecto: true });
        }

        return control.setErrors(null);
    };
}

function validate_ci(ci) {
    if (ci.length == 7) {
        ci = '0' + ci;
    }

    if (ci.length != 8) {
        return false;
    }

    ci = ci.replace(/\D/g, '');
    const dig = ci[ci.length - 1];
    ci = ci.replace(/[0-9]$/, '');

    return dig == validation_digit(ci);
}

function validation_digit(ci) {
    var a = 0;
    var i = 0;
    for (i = 0; i < 7; i++) {
        a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10;
    }
    if (a % 10 === 0) {
        return 0;
    } else {
        return 10 - (a % 10);
    }
}
