import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AvatarModule } from 'ngx-avatar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { EmpresasListadoComponent } from './components/empresas/listado/listado.component';
import { ArchivosSubirComponent } from './components/archivos/subir/subir.component';
import { FormsModule } from '@angular/forms';
import { CiPipe } from './shared/pipes/ci.pipe';
import { FuncionariosService } from './services/funcionarios.service';
import { UsuariosService } from './services/usuarios.service';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MainComponent } from './components/layouts/main/main.component';
import { routing } from './routing.module';
import { FuncionariosListadoComponent } from './components/funcionarios/listado/listado.component';
import { FuncionarioComponent } from './components/funcionarios/index/funcionario.component';
import { DocumentosService } from './services/documentos.service';
import { ArchivosListadoComponent } from './components/archivos/listado/listado.component';
import { LoginComponent } from './components/site/login/index/index.component';
import { RegistroComponent } from './components/site/registro/registro.component';
import { UsuariosEditarComponent } from './components/usuarios/editar/editar.component';
import { ArchivosBottomSheetComponent } from './components/archivos/bottom-sheet/bottom-sheet.component';
import { FuncionariosBottomSheetComponent } from './components/funcionarios/bottom-sheet/bottom-sheet.component';
import { EmpresaComponent } from './components/empresas/index/empresa.component';
import { RegistrarComponent } from './components/empresas/registrar/registrar.component';
import { EmpresasService } from './services/empresas.service';
import { EventEmitterService } from './services/shared.service';
import { VerificarComponent } from './components/site/verificar/verificar.component';
import { EmpresasEditarComponent } from './components/empresas/editar/editar.component';
import { ReseteoContrasenaComponent } from './components/site/login/reseteo-contrasena/reseteo-contrasena.component';
import { ResetearContrasenaComponent } from './components/site/resetear-contrasena/resetear-contrasena.component';
import { RoleUsuarioService } from './auth/role-usuario.service';
import { RoleUsuarioEmpresaService } from './auth/role-usuario-empresa';
import { AuthService } from './auth/auth.service';
import { SiteService } from './services/site.service';
import { InvitacionComponent } from './components/site/invitacion/invitacion.component';
import { ConfirmarCuentaComponent } from './components/site/confirmar-cuenta/confirmar-cuenta.component';
import { FuncionarioDialogComponent } from './components/funcionarios/dialog/dialog.component';
import { EmpresaDialogComponent } from './components/empresas/dialog/dialog.component';
import { MyMaterialModule } from './material.module';
import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeUy from '@angular/common/locales/es-UY';
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { DialogComponent } from './shared/components';
registerLocaleData(localeUy);

@NgModule({
    declarations: [
        AppComponent,
        EmpresasListadoComponent,
        FuncionariosListadoComponent,
        ArchivosSubirComponent,
        CiPipe,
        MainComponent,
        FuncionarioComponent,
        ArchivosListadoComponent,
        LoginComponent,
        RegistroComponent,
        UsuariosEditarComponent,
        ArchivosBottomSheetComponent,
        FuncionariosBottomSheetComponent,
        EmpresaComponent,
        RegistrarComponent,
        VerificarComponent,
        EmpresasEditarComponent,
        ReseteoContrasenaComponent,
        ResetearContrasenaComponent,
        InvitacionComponent,
        ConfirmarCuentaComponent,
        FuncionarioDialogComponent,
        EmpresaDialogComponent,
        DialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AvatarModule,
        MyMaterialModule,
        MatBottomSheetModule,
        routing,
    ],
    providers: [
        FuncionariosService,
        DocumentosService,
        UsuariosService,
        EmpresasService,
        EventEmitterService,
        RoleUsuarioService,
        RoleUsuarioEmpresaService,
        AuthService,
        SiteService,
        DatePipe,
        { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
        { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        { provide: LOCALE_ID, useValue: 'es-UY' },
    ],
    bootstrap: [AppComponent],
    entryComponents: [ArchivosBottomSheetComponent, FuncionariosBottomSheetComponent, FuncionarioDialogComponent, EmpresaDialogComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
