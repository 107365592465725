import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Documentos } from '../models/documentos';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { documentoForm } from '../models/documentoForm';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentosService {
    constructor(private _httpService: HttpClient) {}

    getDocumentos(page: number, pageSize: number, filter = ''): Observable<any> {
        const params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString())
            .set('filter', filter)
            .set('originales', 'true');

        return this._httpService.get<any>(environment.WEB_API_URL + 'documentos-laborales?' + params.toString(), this.jwt());
    }

    cargar(formData: FormData): Observable<documentoForm> {
        return this._httpService.post<documentoForm>(environment.WEB_API_URL + 'documentos-laborales/cargar', formData, this.jwt());
    }

    cargarIndividual(formData: FormData): Observable<documentoForm> {
        return this._httpService.post<documentoForm>(environment.WEB_API_URL + 'documentos-laborales/cargar-individual', formData, this.jwt());
    }

    abrir(id: number): Observable<any> {
        const token = localStorage.getItem('token');
        const httpOptions = {
            responseType: 'blob' as 'json',
            headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
            }),
        };

        return this._httpService.get<any>(environment.WEB_API_URL + 'documentos-laborales/abrir/' + id, httpOptions);
    }

    notificacion(): Observable<Documentos[]> {
        return this._httpService.get<Documentos[]>(environment.WEB_API_URL + 'documentos-laborales/notificacion', this.jwt());
    }

    reprocesar(id: number): Observable<documentoForm> {
        return this._httpService.get<documentoForm>(environment.WEB_API_URL + 'documentos-laborales/reprocesar/' + id, this.jwt());
    }

    eliminar(id: number): Observable<documentoForm> {
        return this._httpService.get<documentoForm>(environment.WEB_API_URL + 'documentos-laborales/eliminar/' + id, this.jwt());
    }

    private jwt() {
        const token = localStorage.getItem('token');
        if (token) {
            const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
            return { headers: headers };
        }
    }
}
