<h2 mat-dialog-title *ngIf="data.titulo">
    {{ data.titulo }}
</h2>

<div mat-dialog-content *ngIf="data.contenido" class="mat-typography">
    <p>{{ data.contenido }}</p>
</div>

<ng-container *ngTemplateOutlet="data.template"></ng-container>

<div mat-dialog-actions fxLayoutAlign="end center" *ngIf="!data.sinBotones">
    <button mat-button mat-dialog-close *ngIf="data.botones.cancelar.mostrar">
        {{ data.botones.cancelar.texto }}
    </button>

    <button mat-raised-button *ngIf="data.botones.aceptar.mostrar" [color]="data.botones.aceptar.color" [disabled]="data.botones.aceptar.deshabilitado" [mat-dialog-close]="true" cdkFocusInitial>
        {{ data.botones.aceptar.texto }}
    </button>
</div>
