<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px">
    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />

    <div fxLayoutAlign="center start">
        <div fxLayout="column" fxFlex="30" fxFlex.md="50" fxFlex.sm="70" fxFlex.xs="95">
            <mat-progress-bar *ngIf="mostrarSpinner" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card class="tarjeta-registro" [class.spinner]="mostrarSpinner">
                <mat-card-header>
                    <mat-card-title>¡Registrate!</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="registroForm" (ngSubmit)="crearUsuario()" fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input autocomplete="off" required matInput type="text" formControlName="nombre" placeholder="Nombre" />
                            <mat-error *ngIf="submitted && f.nombre.errors"> Nombre no puede estar vacío. </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Cédula</mat-label>
                            <input autocomplete="off" required matInput type="cedula" formControlName="documento" placeholder="Cédula" />
                            <mat-error *ngIf="submitted && f.documento.errors && f.documento.errors.required"> Cédula no puede estar vacío. </mat-error>
                            <mat-error *ngIf="submitted && f.documento.errors && f.documento.errors.largoIncorrecto"> Cédula es inválido. </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input autocomplete="off" required matInput type="email" formControlName="email" placeholder="Email" />
                            <mat-error *ngIf="submitted && f.email.errors && f.email.errors.required"> Email no puede estar vacío. </mat-error>
                            <mat-error *ngIf="submitted && f.email.errors && f.email.errors.email"> Email es inválido. </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Celular</mat-label>
                            <input autocomplete="off" matInput type="telefono" formControlName="celular" placeholder="telefono" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input autocomplete="off" required minlength="8" matInput type="password" formControlName="contrasena" placeholder="Contraseña" />
                            <mat-hint>Debe contener 8 dígitos</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Confirmar contraseña</mat-label>
                            <input required minlength="8" matInput type="password" formControlName="repetir_contrasena" placeholder="Confirmar contraseña" />
                            <mat-hint>Debe coincidir con la contraseña</mat-hint>
                            <mat-error *ngIf="submitted && f.repetir_contrasena.errors?.MatchPassword"> Contraseña no coincide. </mat-error>
                        </mat-form-field>

                        <mat-card-actions fxLayout="column" fxLayoutAlign="start center">
                            <button [disabled]="mostrarSpinner" mat-flat-button color="primary">Registrarse</button>
                        </mat-card-actions>

                        <mat-error id="formError" style="display: none">{{ mensajeError }}</mat-error>
                    </form>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-footer fxLayout="column" fxLayoutAlign="start center" class="footer">
                    <p class="texto-gris">¿Ya tenés cuenta?</p>
                    <button mat-flat-button color="link" [routerLink]="['/login']">Iniciar sesión</button>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</div>
