import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Empresas, Usuarios } from 'src/app/models';
import { EmpresasService, EventEmitterService, UsuariosService } from 'src/app/services';
import { ValidadorRut, ValidadorCi, ValidadorEmailCelular } from 'src/app/shared';
import { FuncionarioDialogComponent } from '../../funcionarios/dialog/dialog.component';

@Component({
    selector: 'app-empresas-editar',
    templateUrl: './editar.component.html',
    styleUrls: ['./editar.component.scss'],
})
export class EmpresasEditarComponent implements OnInit {
    empresa: Empresas;
    administradores: Usuarios[];
    invitaciones: any[];

    idEmpresa: number;

    modificarEmpresaForm: FormGroup;
    invitacionForm: FormGroup;
    logoForm: FormGroup;

    edicionEmpresa = false;
    verInvitacion = false;
    submitted = false;
    cargandoAdministradores = true;
    cargandoInvitaciones = true;

    mensajeError = '';

    constructor(
        private formBuilder: FormBuilder,
        private _empresasService: EmpresasService,
        private _usuariosService: UsuariosService,
        private _eventEmitter: EventEmitterService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));

        this.modificarEmpresaForm = this.formBuilder.group(
            {
                razon_social: ['', Validators.required],
                nombre: '',
                rut: ['', Validators.required],
                archivo: '',
            },
            { validator: ValidadorRut() }
        );

        this.invitacionForm = this.formBuilder.group(
            {
                nombre: ['', Validators.required],
                celular: [''],
                documento: [''],
                email: ['', [Validators.email]],
                rol: ['admin_empresa'],
            },
            { validator: [ValidadorCi(), ValidadorEmailCelular()] }
        );

        this.logoForm = this.formBuilder.group({ file: [''] });

        this.cargarEmpresa();
        this.cargarAdministradores();
        this.cargarInvitados();
    }

    cargarEmpresa() {
        this._empresasService.getEmpresa(this.idEmpresa).subscribe((empresa) => {
            this.empresa = empresa;

            this.modificarEmpresaForm = this.formBuilder.group({
                razon_social: [this.empresa.razon_social],
                nombre: [this.empresa.nombre, Validators.required],
                rut: { value: this.empresa.rut, disabled: true },
            });
        });
    }

    onChange(event) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            this.logoForm.get('file').setValue(file);
        }
    }

    editarEmpresa() {
        this.submitted = true;

        if (this.modificarEmpresaForm.invalid) {
            return;
        }

        const formData: FormData = new FormData();

        if (this.logoForm.get('file').value) {
            formData.append('file', this.logoForm.get('file').value, this.logoForm.get('file').value.name);
        }

        formData.append('nombre', this.modificarEmpresaForm.get('nombre').value);
        formData.append('razon_social', this.modificarEmpresaForm.get('razon_social').value);
        formData.append('rut', this.modificarEmpresaForm.get('rut').value);

        this._empresasService.editar(formData).subscribe(
            (response) => {
                this.submitted = false;
                this.edicionEmpresa = false;
                this.mensajeError = '';

                localStorage.setItem('nombreEmpresa', formData.get('nombre').toString());
                this._eventEmitter.sendMessage(this.idEmpresa.toString());

                this.cargarEmpresa();
            },
            (error) => {
                this.submitted = false;
                this.mensajeError = error.error?.message || 'Error al editar empresa';
            }
        );
    }

    invitarUsuario() {
        this.submitted = true;

        if (this.invitacionForm.invalid) {
            return;
        }

        this.cargandoAdministradores = true;

        this._usuariosService.invitar(this.invitacionForm.value).subscribe(
            (response) => {
                this.cargandoAdministradores = false;
                this.verInvitacion = false;

                this.snackBar.open('Invitación enviada correctamente', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });

                this.cargarAdministradores();
                this.cargarInvitados();

                this.invitacionForm.reset();
            },
            (error) => {
                this.cargandoAdministradores = false;

                const msg = error.error?.message || 'No se pudo crear la invitación';
                this.snackBar.open(msg, 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }

    cargarAdministradores() {
        this.cargandoAdministradores = true;

        this._usuariosService.getAdministradores().subscribe(
            (response) => {
                this.administradores = response;
                this.administradores.forEach((a) => (a.iniciales = a.nombre.match(/\b(\w)/g).join('')));

                this.cargandoAdministradores = false;
            },
            (error) => {
                this.snackBar.open('Error al cargar invitaciones', 'OK', { duration: 4000, panelClass: ['error-snackbar'] });
                this.cargandoAdministradores = false;
            }
        );
    }

    cargarInvitados() {
        this.cargandoInvitaciones = true;

        this._usuariosService.getInvitados().subscribe(
            (response) => {
                this.invitaciones = response;
                this.invitaciones.forEach((i) => (i.usuario0.iniciales = i.usuario0.nombre.match(/\b(\w)/g).join('')));

                this.cargandoInvitaciones = false;
            },
            (error) => {
                this.snackBar.open('Error al cargar invitaciones', 'OK', { duration: 4000, panelClass: ['error-snackbar'] });
                this.cargandoInvitaciones = true;
            }
        );
    }

    eliminarAdmin(usuario: Usuarios) {
        const dialogRef = this.dialog.open(FuncionarioDialogComponent, {
            data: { texto: `¿Cambiar a ${usuario.nombre} a funcionario?` },
        });

        dialogRef.afterClosed().subscribe((result) => this.cargarAdministradores());
    }

    reenviar(usuario) {
        const dialogRef = this.dialog.open(FuncionarioDialogComponent, {
            data: { texto: `¿Reenviar invitación a ${usuario.nombre} (${usuario.documento})?` },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.cargandoInvitaciones = true;

                const invitacionForm = this.formBuilder.group({
                    usuario: [usuario.id],
                    rol: ['admin_empresa'],
                });

                this._usuariosService.reenviarInvitacion(invitacionForm.value).subscribe(
                    (response) => this.snackBar.open('Invitación enviada correctamente', 'OK', { duration: 4000, panelClass: ['success-snackbar'] }),
                    (error: any) => this.snackBar.open('No se pudo reenviar la invitación', 'OK', { duration: 4000, panelClass: ['error-snackbar'] }),
                    () => (this.cargandoInvitaciones = false)
                );
            }
        });
    }

    getNombreRol(rol: string) {
        switch (rol) {
            case 'admin_empresa':
                return 'Administrador';
            case 'funcionario':
                return 'Funcionario';
        }
    }

    resetCampos() {
        this.modificarEmpresaForm.reset();
        this.logoForm.reset();
    }

    get f() {
        return this.modificarEmpresaForm.controls;
    }

    get i() {
        return this.invitacionForm.controls;
    }
}
