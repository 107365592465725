import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SiteService } from 'src/app/services/site.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-reseteo-contrasena',
    templateUrl: './reseteo-contrasena.component.html',
    styleUrls: ['./reseteo-contrasena.component.scss'],
})
export class ReseteoContrasenaComponent implements OnInit {
    reseteoForm: FormGroup;

    submitted = false;
    mostrarSpinner = false;
    verificarContrasena = false;

    constructor(private formBuilder: FormBuilder, private _siteService: SiteService, public router: Router) {}

    ngOnInit() {
        this.reseteoForm = this.formBuilder.group({
            usuario: ['', Validators.required],
        });
    }
    get f() {
        return this.reseteoForm.controls;
    }

    solicitarReseteo() {
        this.submitted = true;

        if (this.reseteoForm.invalid) {
            return;
        }

        this.mostrarSpinner = true;
        this._siteService.solicitarReseteo(this.reseteoForm.value).subscribe(
            (response) => {
                this.mostrarSpinner = false;
                this.verificarContrasena = true;
            },
            (error: any) => {
                this.mostrarSpinner = false;
                document.getElementById('formError').style.display = 'initial';
                document.getElementById('formError').innerHTML = error.error?.message || 'Error interno de servidor';
            }
        );
    }
}
