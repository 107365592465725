import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';

import { FuncionariosService, DocumentosService, EventEmitterService, UsuariosService } from 'src/app/services';

import { Documentos, Usuarios } from 'src/app/models';
import { FuncionariosBottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { FuncionarioDialogComponent } from '../dialog/dialog.component';
import { ValidadorCi, ValidadorEmailCelular } from 'src/app/shared';

@Component({
    selector: 'app-funcionarios-listado',
    templateUrl: './listado.component.html',
    styleUrls: ['./listado.component.scss'],
})
export class FuncionariosListadoComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    funcionarios: Usuarios[];

    idEmpresa: number;
    idUsuario: number;

    registroForm: FormGroup;
    fileForm: FormGroup;
    invitacionForm: FormGroup;
    mostrarFuncionariosForm: FormGroup;

    pageIndex = 0;
    pageSize = 10;

    campoBusqueda = '';
    mensajesError = [];

    submitted = false;
    mostrarRegistroFuncionario = false;
    mostrarInactivos = false;
    mostrarPendientes = false;
    mostrarSpinner = false;
    cargandoFuncionarios = false;

    constructor(
        private _funcionariosService: FuncionariosService,
        private _usuariosService: UsuariosService,
        private _documentosService: DocumentosService,
        private _eventEmitter: EventEmitterService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet,
        private datePipe: DatePipe,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
        this.idUsuario = parseInt(localStorage.getItem('idUsuario'));

        this.fileForm = this.formBuilder.group({ file: [''] });

        this.mostrarFuncionariosForm = this.formBuilder.group({
            inactivos: [false],
            pendientes: [false],
        });

        this.mostrarFuncionariosForm.get('inactivos').valueChanges.subscribe((selectedValue) => {
            this.mostrarInactivos = selectedValue;
            this.cargarFuncionarios();
        });

        this.mostrarFuncionariosForm.get('pendientes').valueChanges.subscribe((selectedValue) => {
            this.mostrarPendientes = selectedValue;
            this.cargarFuncionarios();
        });

        this._eventEmitter.actualizarDocumentos.subscribe((data) => {
            this.cargarFuncionarios();
        });

        this.initForm();
        this.cargarFuncionarios();
    }

    cargarFuncionarios() {
        this.cargandoFuncionarios = true;

        this._funcionariosService
            .getFuncionarios(this.pageIndex + 1, this.pageSize, this.campoBusqueda, !this.mostrarInactivos, this.mostrarPendientes)
            .subscribe(
                (response) => {
                    this.funcionarios = response.data;
                    this.paginator.length = response.total;

                    this.funcionarios.forEach((funcionario) => {
                        funcionario.iniciales = funcionario.nombre.match(/\b(\w)/g).join('');

                        if (funcionario.id == this.idUsuario) {
                            funcionario.nombre = funcionario.nombre + ' (yo)';
                        }

                        this.getDocumentosRecientes(funcionario);
                    });

                    this.cargandoFuncionarios = false;
                },
                (error: any) => (this.cargandoFuncionarios = false)
            );
    }

    getDocumentosRecientes(funcionario: Usuarios) {
        this._funcionariosService.getDocumentos(funcionario.id, 1, 3).subscribe((response) => (funcionario.documentos = response.data.slice(0, 3)));
    }

    invitar(funcionario: Usuarios) {
        const dialogRef = this.dialog.open(FuncionarioDialogComponent, {
            data: {
                texto: `¿Reenviar invitación a ${funcionario.nombre}?`,
                email: funcionario.email,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.reenviarInvitacion(funcionario);
            }
        });
    }

    reenviarInvitacion(funcionario: Usuarios) {
        this.invitacionForm = this.formBuilder.group({
            usuario: [funcionario.id],
            rol: ['funcionario'],
        });

        this._usuariosService.reenviarInvitacion(this.invitacionForm.value).subscribe(
            (response) => this.snackBar.open('Invitación enviada correctamente', 'OK', { duration: 4000, panelClass: ['success-snackbar'] }),
            (error: any) => this.snackBar.open('No se pudo reenviar la invitación', 'OK', { duration: 4000, panelClass: ['error-snackbar'] })
        );
    }

    crearFuncionario() {
        this.submitted = true;

        if (this.registroForm.invalid) {
            return;
        }

        this.mostrarSpinner = true;
        this.registroForm.value.empresa = this.idEmpresa;

        this._funcionariosService.registrar(this.registroForm.value).subscribe(
            (response) => {
                this.snackBar.open('Invitación enviada correctamente', 'OK', { duration: 4000, panelClass: ['success-snackbar'] });

                this.mostrarSpinner = false;
                this.mostrarRegistroFuncionario = false;

                this.cargarFuncionarios();
                this.initForm();
            },
            (error) => {
                this.mostrarSpinner = false;
                const jsonError = JSON.parse(error.error?.message);
                this.mensajesError = Object.values(jsonError);
            }
        );
    }

    initForm() {
        this.registroForm = this.formBuilder.group(
            {
                nombre: ['', Validators.required],
                celular: [''],
                documento: ['', Validators.required],
                email: ['', Validators.email],
                rol: ['funcionario'],
            },
            { validators: [ValidadorCi(), ValidadorEmailCelular()] } as AbstractControlOptions
        );

        this.mensajesError = [];
    }

    fileChange(event: any, id: number) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            this.fileForm.get('file').setValue(file);
            this.subirArchivo(id);
        }
    }

    subirArchivo(id: number | string) {
        const formData: FormData = new FormData();
        formData.append('file', this.fileForm.get('file').value, this.fileForm.get('file').value.name);
        formData.append('empresa', this.idEmpresa.toString());
        formData.append('usuario', id.toString());

        this._documentosService.cargarIndividual(formData).subscribe((response) => {
            if (response.file) {
                this.snackBar.open('No se pudo cargar el archivo', 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            } else {
                this.cargarFuncionarios();
                this._eventEmitter.actualizarListadoDocumentos('reload');
                this.snackBar.open('¡Archivo subido con éxito!', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });
            }
        });
    }

    abrirDocumento(documento: Documentos): void {
        this._documentosService.abrir(documento.id).subscribe(
            (response) => {
                this.snackBar.open('Es posible que sea necesario habilitar los pop-ups', 'OK', {
                    duration: 6000,
                    panelClass: ['info-snackbar'],
                });

                const blob = new Blob([response], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            },
            (error) => this.snackBar.open('Error al abrir el archivo', 'OK', { duration: 6000, panelClass: ['error-snackbar'] })
        );
    }

    cambiarPagina() {
        this.pageIndex = this.paginator.pageIndex;
        this.pageSize = this.paginator.pageSize;
        this.cargarFuncionarios();
    }

    openBottomSheet(): void {
        this.bottomSheet.open(FuncionariosBottomSheetComponent);
    }

    tooltipDocumento(documento: Documentos) {
        let texto = documento.archivo0.nombre_origen + '\n';
        texto += 'Creado: ' + this.datePipe.transform(documento.fecha_creacion.toString(), 'd/M/Y, H:mm:ss') + '\n';

        if (documento.fecha_aceptado) {
            texto += 'Visto: ' + this.datePipe.transform(documento.fecha_aceptado.toString(), 'd/M/Y, H:mm:ss') + '\n';
        } else {
            texto += 'Pendiente';
        }

        return texto;
    }

    irFuncionario(funcionario) {
        if (funcionario.id == this.idUsuario) {
            return ['/usuarios/editar'];
        }

        return this.esTablaPendientes ? [] : ['/funcionarios', funcionario.id];
    }

    get f() {
        return this.registroForm.controls;
    }

    get esTablaPendientes() {
        return this.mostrarFuncionariosForm.get('pendientes').value;
    }
}
