import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { DocumentosService, EventEmitterService } from 'src/app/services';

@Component({
    selector: 'app-archivos-subir',
    templateUrl: './subir.component.html',
    styleUrls: ['./subir.component.scss'],
})
export class ArchivosSubirComponent implements OnInit {
    @ViewChild('fileInput') fileInput: ElementRef;

    @Input() esFuncionario = false;

    @Output() actualizarListadoDocumentos = new EventEmitter();

    idEmpresa: number;

    form: FormGroup;
    mensajesError = [];

    hideArchivo = true;
    subiendo = false;

    constructor(
        private formBuilder: FormBuilder,
        private _documentosService: DocumentosService,
        private _eventEmitter: EventEmitterService,
        private snackBar: MatSnackBar,
        public router: Router
    ) {}

    ngOnInit() {
        this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));

        this.form = this.formBuilder.group({ file: [''] });
    }

    onChange(event) {
        this.mensajesError = [];

        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get('file').setValue(file);
        }

        this.hideArchivo = false;
    }

    subirArchivo() {
        this.subiendo = true;

        const formData = new FormData();

        formData.append('file', this.form.get('file').value, this.form.get('file').value.name);
        formData.append('empresa', this.idEmpresa.toString());

        if (this.esFuncionario) {
            this._documentosService.cargarIndividual(formData).subscribe(
                (response) => this.procesarRespuesta(),
                (error) => this.errorCargar(error)
            );
        } else {
            this._documentosService.cargar(formData).subscribe(
                (response) => this.procesarRespuesta(),
                (error) => this.errorCargar(error)
            );
        }
    }

    errorCargar(error) {
        this.subiendo = false;

        const jsonError = JSON.parse(error.error?.message);
        this.mensajesError = Object.values(jsonError);
    }

    procesarRespuesta() {
        this.subiendo = false;

        this.fileInput.nativeElement.value = '';

        this.hideArchivo = true;

        this.snackBar.open('¡Archivo subido con éxito!', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });
        this._eventEmitter.actualizarListadoDocumentos('reload');
        this._eventEmitter.actualizarListadoFuncionarios('reload');
    }

    cerrarArchivo() {
        this.hideArchivo = true;
        this.form.reset;
    }
}
