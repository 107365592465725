<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px">
    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />

    <div fxLayoutAlign="center start">
        <div fxLayout="column" fxFlex="30" fxFlex.md="40" fxFlex.sm="60" fxFlex.xs="90">
            <mat-progress-bar *ngIf="mostrarLoader" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card class="tarjeta-login" [class.spinner]="mostrarLoader" *ngIf="valido">
                <mat-card-header>
                    <mat-card-title>Nueva contraseña</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="resetearForm" (ngSubmit)="resetear()" fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Nueva contraseña</mat-label>
                            <input required matInput type="password" formControlName="contrasena" [ngClass]="{ 'is-invalid': submitted && f.contrasena.errors }" autocomplete="doNotAutoComplete" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Repetir</mat-label>
                            <input
                                required
                                matInput
                                type="password"
                                formControlName="repetir_contrasena"
                                [ngClass]="{ 'is-invalid': submitted && f.repetir_contrasena.errors }"
                                autocomplete="doNotAutoComplete"
                            />
                        </mat-form-field>

                        <mat-error id="formError" style="display: none"></mat-error>

                        <mat-card-actions fxLayout="column" fxLayoutAlign="start center">
                            <div fxLayout="column" fxLayoutAlign="space-between center">
                                <button mat-flat-button color="primary">Resetear contraseña</button>
                                <button mat-button class="iniciar-sesion" [routerLink]="['/login']">Iniciar sesión</button>
                            </div>
                        </mat-card-actions>
                    </form>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-footer fxLayout="column" fxLayoutAlign="start center" class="footer">
                    <p class="textoGris">¿No tenés cuenta?</p>
                    <button mat-flat-button color="link" (click)="(onAction1)" [routerLink]="['/registro']">Registrate</button>
                </mat-card-footer>
            </mat-card>

            <mat-card class="tarjeta-login" [class.spinner]="mostrarSpinner" *ngIf="!cargando && !valido">
                <mat-card-header fxLayoutAlign="center center">
                    <mat-card-title>ERROR</mat-card-title>
                </mat-card-header>

                <mat-card-content fxLayoutAlign="center center">
                    <p>El código es inválido o ya fue utilizado.</p>
                </mat-card-content>

                <mat-card-footer fxLayout="column" fxLayoutAlign="start center">
                    <p class="textoGris">Para volver a la página principal:</p>
                    <a mat-flat-button color="primary" [routerLink]="['/login']">Iniciar sesión</a>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</div>
