import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteService } from 'src/app/services/site.service';

@Component({
    selector: 'app-login',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public loginError: String;

    public submitted = false;
    public mostrarSpinner = false;

    constructor(private _siteService: SiteService, private formBuilder: FormBuilder) {}

    ngOnInit() {
        localStorage.clear();

        this.loginForm = this.formBuilder.group({
            usuario: ['', Validators.required],
            contrasena: ['', Validators.required],
        });
    }

    login() {
        this.submitted = true;
        this.loginError = undefined;

        if (this.loginForm.invalid) {
            return;
        }

        this.mostrarSpinner = true;

        this._siteService.login(this.loginForm.value).subscribe(
            (res) => {},
            (err) => {
                this.submitted = false;
                this.mostrarSpinner = false;
                this.loginError = err?.error.message ? err?.error.message : 'Error al iniciar sesión';
            }
        );
    }

    get f() {
        return this.loginForm.controls;
    }
}
