import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { Usuarios, UsuariosEmpresas } from 'src/app/models';
import { FuncionariosService, EventEmitterService } from 'src/app/services';

import { FuncionarioDialogComponent } from '../dialog/dialog.component';

@Component({
    selector: 'app-funcionario',
    templateUrl: './funcionario.component.html',
    styleUrls: ['./funcionario.component.scss'],
})
export class FuncionarioComponent implements OnInit {
    funcionario: Usuarios;
    usuariosEmpresas: UsuariosEmpresas;

    idFuncionario: number;
    idEmpresa: number;
    idUsuario: number;
    iniciales: string;

    registroForm: FormGroup;
    reseteoForm: FormGroup;

    esAdmin = false;
    esEditarFuncionario = false;
    esFuncionarioActivo = true;
    submitted = false;
    cargando = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _funcionariosService: FuncionariosService,
        private _eventEmitter: EventEmitterService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.idFuncionario = parseInt(this.route.snapshot.params['id']);
        this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
        this.idUsuario = parseInt(localStorage.getItem('idUsuario'));

        if (this.idFuncionario) {
            localStorage.setItem('idFuncionario', this.idFuncionario.toString());

            this.cargarFuncionario();
            this.definicionUsuarioFuncionario();

            this._eventEmitter.sendMessage(this.idEmpresa.toString());
        }
    }

    definicionUsuarioFuncionario() {
        this.esAdmin = localStorage.getItem('rol') === 'admin_empresa';
    }

    cargarFuncionario() {
        this._funcionariosService.getFuncionario(this.idFuncionario).subscribe(
            (funcionario: Usuarios) => {
                this.funcionario = funcionario;
                if (funcionario) {
                    this.cargarForm();
                    this.definirIniciales();
                }
            },
            (error: any) => console.log(error)
        );
    }

    definirIniciales() {
        const extraer = this.funcionario.nombre.match(/\b(\w)/g);
        this.iniciales = extraer.join('');
    }

    cargarForm() {
        this.registroForm = this.formBuilder.group({
            id: [this.funcionario.id],
            nombre: [this.funcionario.nombre],
            celular: [this.funcionario.celular],
            documento: { value: this.funcionario.documento, disabled: true },
            email: [this.funcionario.email, Validators.email],
        });
    }

    editarFuncionario() {
        this.esEditarFuncionario = true;
        this.submitted = true;

        if (this.registroForm.invalid) {
            this.cargando = false;
            this.submitted = false;
            return;
        }

        this.cargando = true;

        this._funcionariosService.editar(this.idFuncionario, this.registroForm.value).subscribe(
            (response) => {
                this.snackBar.open('Usuario modificado correctamente', 'OK', { duration: 2000, panelClass: ['success-snackbar'] });

                this.submitted = false;
                this.cargando = false;
                this.esEditarFuncionario = false;

                this._eventEmitter.sendMessage(this.idEmpresa.toString());
                this.cargarFuncionario();
            },
            (error) => {
                this.submitted = false;
                this.cargando = false;
                this.snackBar.open(error.error?.message || 'Error al editar funcionario', 'OK', { duration: 2000, panelClass: ['error-snackbar'] });
            },
            () => (this.cargando = false)
        );
    }

    estadoFuncionario(estado: boolean) {
        const texto = estado ? 'Activar' : 'Desactivar';
        const dialogRef = this.dialog.open(FuncionarioDialogComponent, {
            autoFocus: false,
            data: {
                texto: `¿${texto} ${this.funcionario.nombre}?`,
                restablecerContrasena: false,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._funcionariosService.desactivar(this.idFuncionario).subscribe((response) => {
                    this.router.navigateByUrl('/empresas/' + this.idEmpresa);
                });
            }
        });
    }

    eliminarFuncionario() {
        this._funcionariosService.eliminar(this.idFuncionario).subscribe((response) => {
            if (response) {
                this.router.navigateByUrl('/empresas/' + this.idEmpresa);
            }
        });
    }

    modificarFuncionario() {
        this.submitted = true;
        if (this.registroForm.invalid) {
            return;
        }
    }

    restablecerContrasenaDialog() {
        const dialogRef = this.dialog.open(FuncionarioDialogComponent, {
            autoFocus: false,
            data: {
                texto: `¿Enviar reseteo de contraseña a ${this.funcionario.nombre}?`,
                restablecerContrasena: true,
                ocultarCelular: !this.funcionario.celular,
                ocultarEmail: !this.funcionario.email,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.enviarReseteo(result);
                this.esEditarFuncionario = false;
            }
        });
    }

    enviarReseteo(result) {
        this.cargando = true;

        this.reseteoForm = this.formBuilder.group({
            usuario: [this.idFuncionario],
            email: [result.email],
            celular: [result.celular],
        });

        this._funcionariosService.resetearContrasena(this.reseteoForm.value).subscribe(
            (response) => this.snackBar.open('Solicitud enviada correctamente', 'OK', { duration: 2000, panelClass: ['success-snackbar'] }),
            (error: any) => this.snackBar.open(error.error?.message, 'OK', { duration: 2000, panelClass: ['error-snackbar'] }),
            () => (this.cargando = false)
        );
    }

    resetCampos = () => this.registroForm.reset();

    get f() {
        return this.registroForm.controls;
    }
}
