<div fxLayout.lt-md="column" fxLayoutAlign="space-evenly start" fxLayoutAlign.lt-md="center stretch" fxLayoutGap.lt-md="15px">
    <div fxFlex fxFlex.gt-sm="45" fxLayout="column" fxLayoutAlign="center" *ngIf="esAdmin" class="info-funcionario">
        <mat-progress-bar *ngIf="cargando" mode="indeterminate" color="primary"> </mat-progress-bar>

        <mat-card fxFlex fxFlex.lt-md="95" [class.cargando]="cargando">
            <mat-chip-list class="inactivo-chip" *ngIf="!funcionario.usuariosEmpresas.activo">
                <mat-chip color="warn" selected>Inactivo</mat-chip>
            </mat-chip-list>

            <div fxLayoutAlign="space-evenly center">
                <ngx-avatar size="100" value="{{ iniciales }}"> </ngx-avatar>
            </div>

            <div fxLayout="column" fxLayoutAlign="start stretch" style="margin-top: 20px">
                <div *ngIf="!esEditarFuncionario" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <h3>{{ funcionario?.nombre }}</h3>

                    <div fxLayoutGap="5px">
                        <div fxFlex="15" fxLayout="column" fxLayoutAlign=" center" class="iconos">
                            <span fxFlex="30px"><mat-icon>credit_card</mat-icon></span>
                            <span fxFlex="30px"><mat-icon>smartphone</mat-icon></span>
                            <span fxFlex="30px"><mat-icon>alternate_email</mat-icon></span>
                        </div>
                        <div fxFlex="85" fxLayout="column">
                            <span fxFlex="30px">{{ funcionario?.documento | ciPipe }}</span>
                            <span fxFlex="30px">{{ funcionario?.celular || '-' }}</span>
                            <span fxFlex="30px">{{ funcionario?.email || '-' }}</span>
                        </div>
                    </div>

                    <div fxLayoutAlign="space-evenly center"></div>

                    <div fxLayoutAlign="center center" fxLayoutGap="5px" *ngIf="idFuncionario != idUsuario">
                        <button mat-flat-button type="button" color="primary" (click)="restablecerContrasenaDialog()">Restablecer contraseña</button>
                        <button mat-flat-button type="button" color="accent" *ngIf="funcionario?.usuariosEmpresas.activo" (click)="estadoFuncionario(0)">Desactivar</button>
                        <button mat-flat-button type="button" color="warn" *ngIf="!funcionario?.usuariosEmpresas.activo" (click)="estadoFuncionario(1)">Activar</button>
                        <button mat-flat-button type="button" color="warn" (click)="eliminarFuncionario()" *ngIf="false">Eliminar</button>
                        <button mat-flat-button (click)="esEditarFuncionario = true" color="primary">Editar</button>
                    </div>
                </div>

                <div *ngIf="esEditarFuncionario" fxLayoutAlign="center start">
                    <form fxFlex="80" fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="registroForm" (ngSubmit)="editarFuncionario()">
                        <mat-form-field appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Cédula</mat-label>
                            <input matInput formControlName="documento" [ngClass]="{ 'is-invalid': submitted && f.documento.errors }" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Celular</mat-label>
                            <input matInput formControlName="celular" [ngClass]="{ 'is-invalid': submitted && f.celular.errors }" />
                        </mat-form-field>

                        <div class="cancelar-editar" fxLayoutAlign="end center" fxLayoutGap="5px">
                            <button mat-button type="button" (click)="esEditarFuncionario = false">Cancelar</button>
                            <button mat-flat-button type="submit" color="primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </mat-card>
    </div>

    <div fxFlex="45" fxFlex.lt-md fxLayoutAlign="center" class="documentos">
        <div fxFlex fxFlex.lt-md="95">
            <app-archivos-listado [esFuncionario]="true"></app-archivos-listado>
        </div>
    </div>
</div>
