import { ErrorHandler } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from 'src/environments/environment';

export class AirbrakeErrorHandler implements ErrorHandler {
    airbrake: Notifier;

    constructor() {
        if (environment.SUELDOS_ENV == 'production') {
            this.airbrake = new Notifier({
                projectId: environment.AIRBRAKE_PROJECT_ID,
                projectKey: environment.AIRBRAKE_PROJECT_KEY,
                environment: environment.SUELDOS_ENV,
            });
        }
    }

    handleError(error: any): void {
        if (environment.SUELDOS_ENV == 'production') {
            this.airbrake.notify(error);
        }
    }
}
