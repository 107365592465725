import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class RoleUsuarioEmpresaService implements CanActivate {
    constructor(public auth: AuthService, public router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedRole = route.data.expectedRole;
        const token = localStorage.getItem('token');

        if (!token || !this.auth.isAuthenticated()) {
            this.router.navigateByUrl('/login');
        }

        if (!expectedRole) {
            return true;
        }

        const empresa = localStorage.getItem('idEmpresa');
        const rol = localStorage.getItem('rol');

        if (!empresa) {
            this.router.navigateByUrl('/empresas');
        }

        return expectedRole.includes(rol);
    }
}
