import { AbstractControl } from '@angular/forms';
export class PasswordValidation {
    static MatchPassword(AC: AbstractControl) {
        const password = AC.get('contrasena').value;
        const confirmPassword = AC.get('repetir_contrasena').value;

        if (password != confirmPassword) {
            AC.get('repetir_contrasena').setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }
}
