<h2 mat-dialog-title>{{ data.texto }}</h2>

<div mat-dialog-content fxLayout="column" *ngIf="restablecerContrasena">
    <mat-checkbox [(ngModel)]="celular" *ngIf="!ocultarCelular">Celular</mat-checkbox>
    <mat-checkbox [(ngModel)]="email" *ngIf="!ocultarEmail">Email</mat-checkbox>
</div>

<div mat-dialog-actions>
    <div fxFill fxLayoutAlign="end">
        <button mat-button (click)="cancelar()">Cancelar</button>
        <button mat-flat-button color="primary" (click)="enviar()">Enviar</button>
    </div>
</div>
