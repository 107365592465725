import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
    selector: 'app-confirmar-cuenta',
    templateUrl: './confirmar-cuenta.component.html',
    styleUrls: ['./confirmar-cuenta.component.scss'],
})
export class ConfirmarCuentaComponent implements OnInit {
    codigo: String;
    titulo: String;
    mensaje: String;

    cargando = true;

    constructor(private usuariosService: UsuariosService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.codigo = this.route.snapshot.queryParams['codigo'];
        this.confirmarCuenta();
    }

    confirmarCuenta() {
        this.usuariosService.confirmarCuenta(this.codigo).subscribe(
            (response) => {
                this.cargando = false;

                if (!response) {
                    this.titulo = 'Error';
                    this.mensaje = 'El código es inválido o ya fue utilizado';
                } else {
                    this.titulo = '¡Bienvenido!';
                    this.mensaje = 'Ahora tu cuenta se encuentra activada';
                }
            },
            (error) => {
                this.cargando = false;
                this.mensaje = error.error?.message || 'El código es inválido o ya fue utilizado';
                this.titulo = 'Error';
            }
        );
    }
}
