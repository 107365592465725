import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { Empresas } from 'src/app/models';
import { EventEmitterService } from './shared.service';

@Injectable()
export class EmpresasService {
    private helper = new JwtHelperService();

    constructor(private _httpService: HttpClient, private router: Router, private _eventEmitter: EventEmitterService) {}

    getEmpresa(id: number): Observable<Empresas> {
        return this._httpService.get<Empresas>(environment.WEB_API_URL + 'empresas/' + id, this.jwt());
    }

    getEmpresas(): Observable<Empresas[]> {
        return this._httpService.get<Empresas[]>(environment.WEB_API_URL + 'empresas', this.jwt());
    }

    registrar(formData: FormData): Observable<Empresas> {
        return this._httpService.post<Empresas>(environment.WEB_API_URL + 'empresas/registrar', formData, this.jwt());
    }

    editar(formData: FormData): Observable<Empresas> {
        return this._httpService.post<Empresas>(environment.WEB_API_URL + 'empresas/editar', formData, this.jwt());
    }

    seleccionar(id: number, rol: string) {
        const params = new HttpParams().set('id', id.toString()).set('rol', rol);

        this._httpService.get<any>(environment.WEB_API_URL + 'empresas/seleccionar?' + params.toString(), this.jwt()).subscribe(
            (response) => {
                const token = response.token;
                const decodedToken = this.helper.decodeToken(token);
                localStorage.setItem('token', token);
                localStorage.setItem('idEmpresa', id.toString());
                localStorage.setItem('nombreEmpresa', decodedToken.nombre_empresa);
                localStorage.setItem('rol', decodedToken.rol);

                this._eventEmitter.sendMessage(id.toString());

                if (token) {
                    if (decodedToken.rol == 'funcionario') {
                        this.router.navigateByUrl('/funcionarios/' + decodedToken.id);
                    } else {
                        this.router.navigateByUrl('/empresas/' + id);
                    }
                }
            },
            (error) => console.log(error)
        );
    }

    private jwt() {
        const token = localStorage.getItem('token');
        if (token) {
            const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
            return { headers: headers };
        }
    }
}
