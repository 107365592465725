import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    nombreUsuario = '';
    nombreEmpresa = '';

    empresaSeleccionada = false;
    esAdmin = false;

    constructor(private _eventEmitter: EventEmitterService) {}

    ngOnInit(): void {
        this.esAdmin = localStorage.getItem('rol') === 'admin_empresa';
        this.nombreUsuario = localStorage.getItem('nombreUsuario') || '';
        this.nombreEmpresa = localStorage.getItem('nombreEmpresa') || '';
        this.empresaSeleccionada = !!localStorage.getItem('idEmpresa');

        this._eventEmitter.actualizarToolBar.subscribe(() => {
            this.nombreUsuario = localStorage.getItem('nombreUsuario') || '';
            this.nombreEmpresa = localStorage.getItem('nombreEmpresa') || '';
            this.empresaSeleccionada = !!localStorage.getItem('idEmpresa');
        });
    }
}
