import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Usuarios } from 'src/app/models';
import { UsuariosService, EventEmitterService } from 'src/app/services';
import { PasswordValidation, ValidadorCi } from 'src/app/shared';

@Component({
    selector: 'app-usuarios-editar',
    templateUrl: './editar.component.html',
    styleUrls: ['./editar.component.scss'],
})
export class UsuariosEditarComponent implements OnInit {
    usuario: Usuarios;

    idEmpresa: number;

    modificarUsuarioForm: FormGroup;
    cambiarContrasenaForm: FormGroup;

    submitted = false;
    cambiarContrasena = false;
    edicionUsuario = false;
    mostrarSpinnerUsuario = false;
    mostrarSpinnerContrasena = false;

    constructor(
        private _usuariosService: UsuariosService,
        private _eventEmitter: EventEmitterService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));

        this.modificarUsuarioForm = this.formBuilder.group({
            nombre: [''],
            celular: [''],
            email: [''],
            documento: [''],
        });

        this.cambiarContrasenaForm = this.formBuilder.group(
            {
                contrasena: ['', [Validators.required, Validators.min(8)]],
                repetir_contrasena: [''],
            },
            { validator: PasswordValidation.MatchPassword }
        );

        if (this.idEmpresa) {
            this._eventEmitter.sendMessage(this.idEmpresa.toString());
        }

        this.getUsuario();
    }

    getUsuario(): void {
        this._usuariosService.getUsuario().subscribe(
            (usuario: Usuarios) => {
                this.usuario = usuario;
                this.cargarForm();
            },
            (error: any) => console.log(error)
        );
    }

    cargarForm() {
        this.modificarUsuarioForm = this.formBuilder.group(
            {
                id: [this.usuario.id],
                nombre: [this.usuario.nombre, Validators.required],
                email: { value: this.usuario.email, disabled: true },
                celular: { value: this.usuario.celular, disabled: true },
                documento: { value: this.usuario.documento, disabled: true },
            },
            { validator: ValidadorCi() }
        );

        this.cambiarContrasenaForm = this.formBuilder.group(
            {
                contrasena: ['', [Validators.required, Validators.min(8)]],
                repetir_contrasena: [''],
            },
            { validator: PasswordValidation.MatchPassword }
        );
    }

    editarUsuario() {
        if (this.modificarUsuarioForm.invalid) {
            return;
        }

        this.submitted = true;
        this.mostrarSpinnerUsuario = true;

        this._usuariosService.editar(this.modificarUsuarioForm.value).subscribe(
            (response) => {
                this.snackBar.open('¡Se modificó el usuario!', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });

                this.getUsuario();
                this.edicionUsuario = false;
                this.mostrarSpinnerUsuario = false;

                localStorage.setItem('nombreUsuario', this.modificarUsuarioForm.get('nombre').value);
                this._eventEmitter.sendMessage(this.idEmpresa.toString());
            },
            (error) => {
                this.mostrarSpinnerUsuario = false;
                const msj = error.error?.message || 'Error al editar usuario';
                this.snackBar.open(msj, 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }

    editarContrasena() {
        if (!this.cambiarContrasena) {
            this.verificarContrasena();
            return;
        }

        if (this.cambiarContrasenaForm.invalid) {
            return;
        }

        this.submitted = true;
        this.mostrarSpinnerContrasena = true;

        this._usuariosService.cambiarContrasena(this.cambiarContrasenaForm.value).subscribe(
            (response) => {
                this.mostrarSpinnerContrasena = false;
                this.cambiarContrasena = false;

                this.snackBar.open('¡Se modificó la contraseña!', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });
                this.resetCampos();
            },
            (error: any) => {
                this.mostrarSpinnerContrasena = false;
                const msj = error.error?.message || 'Error al guardar contraseña';
                this.snackBar.open(msj, 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }

    verificarContrasena() {
        if (this.cambiarContrasenaForm.get('contrasena').invalid) {
            return;
        }

        this.submitted = true;
        this.mostrarSpinnerContrasena = true;

        this._usuariosService.verificarContrasena(this.cambiarContrasenaForm.get('contrasena').value).subscribe(
            (response) => {
                this.submitted = false;
                this.mostrarSpinnerContrasena = false;
                this.cambiarContrasena = true;

                this.snackBar.open('Elegir contraseña nueva', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });
                this.resetCampos();
            },
            (error: any) => {
                this.mostrarSpinnerContrasena = false;
                this.cambiarContrasenaForm.get('contrasena').setErrors({ error: true });
                const msj = error.error?.message || 'Error al verificar contraseña';
                this.snackBar.open(msj, 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
            }
        );
    }

    resetCampos = () => {
        this.cambiarContrasenaForm.reset();
        this.cambiarContrasenaForm.get('contrasena').setErrors(null);
    };

    get f() {
        return this.modificarUsuarioForm.controls;
    }

    get p() {
        return this.cambiarContrasenaForm.controls;
    }
}
