import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ArchivosBottomSheetComponent } from 'src/app/components/archivos/bottom-sheet/bottom-sheet.component';
import { DocumentosService, FuncionariosService, EventEmitterService } from 'src/app/services';
import { Documentos } from 'src/app/models';
import { DialogConfig, DialogData } from 'src/app/shared/models';
import { DialogComponent } from 'src/app/shared/components';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-archivos-listado',
    templateUrl: './listado.component.html',
    styleUrls: ['./listado.component.scss'],
})
export class ArchivosListadoComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Input() esFuncionario = false;

    idFuncionario: number;
    columnasAMostrar = ['archivo', 'fecha_creacion'];
    dataSource = new MatTableDataSource<any>();

    pageIndex = 0;
    pageSize = 10;

    campoBusqueda = '';

    esAdmin: boolean;
    cargandoArchivos = false;
    eliminandoDocumentoId = undefined;

    constructor(
        private _documentosService: DocumentosService,
        private _functionariosService: FuncionariosService,
        private _eventEmitter: EventEmitterService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
        private route: ActivatedRoute
    ) {
        this.esAdmin = localStorage.getItem('rol') == 'admin_empresa';
    }

    ngOnInit() {
        if (this.esAdmin) {
            this.columnasAMostrar = !this.esFuncionario
                ? ['subido_por', 'archivo', 'fecha_creacion']
                : ['subido_por', 'archivo', 'fecha_creacion', 'visto', 'mas'];
        }

        if (this.esFuncionario) {
            this.idFuncionario = parseInt(this.route.snapshot.params['id']);
        }

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.cargarArchivos();

        this._eventEmitter.actualizarDocumentos.subscribe(() => {
            this.cargarArchivos();
            this.bottomSheet.dismiss();
        });
    }

    cargarArchivos() {
        this.cargandoArchivos = true;

        if (this.esFuncionario) {
            this._functionariosService.getDocumentos(this.idFuncionario, this.pageIndex + 1, this.pageSize, this.campoBusqueda).subscribe(
                (response) => {
                    this.dataSource.data = response.data;
                    this.paginator.length = response.total;
                    this.cargandoArchivos = false;
                },
                (error) => (this.cargandoArchivos = false)
            );

            return;
        }

        this._documentosService.getDocumentos(this.pageIndex + 1, this.pageSize, this.campoBusqueda).subscribe(
            (response) => {
                this.dataSource.data = response.data;
                this.paginator.length = response.total;
                this.cargandoArchivos = false;
            },
            (error) => (this.cargandoArchivos = false)
        );
    }

    cambiarPagina() {
        this.pageIndex = this.paginator.pageIndex;
        this.pageSize = this.paginator.pageSize;
        this.cargarArchivos();
    }

    abrirDocumento(documento: Documentos): void {
        if (!this.esFuncionario) {
            this.bottomSheet.open(ArchivosBottomSheetComponent, { data: documento, closeOnNavigation: true, hasBackdrop: true });
            return;
        }

        this._documentosService.abrir(documento.id).subscribe(
            (response) => {
                this._snackBar.open('Es posible que sea necesario habilitar los pop-ups', 'OK', {
                    duration: 6000,
                    panelClass: ['info-snackbar'],
                });

                const blob = new Blob([response], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            },
            (error) => this._snackBar.open('Error al abrir el archivo', 'OK', { duration: 6000, panelClass: ['error-snackbar'] })
        );
    }

    eliminarDocumento(documento: Documentos) {
        if (this.esAdmin) {
            const dialogData = new DialogData();
            const dialogConfig = new DialogConfig({ ...dialogData, titulo: '¿Eliminar documento?' });

            this._dialog
                .open(DialogComponent, dialogConfig)
                .beforeClosed()
                .subscribe((resultado) => {
                    if (resultado) {
                        this.eliminandoDocumentoId = documento.id;

                        this._documentosService.eliminar(documento.id).subscribe(
                            (response) => {
                                this._snackBar.open('Documento eliminado', 'OK', { duration: 6000, panelClass: ['success-snackbar'] });
                                this._eventEmitter.actualizarListadoDocumentos('reload');
                                this._eventEmitter.actualizarListadoFuncionarios('reload');
                                this.eliminandoDocumentoId = undefined;
                            },
                            (error) => {
                                this._snackBar.open('Error al eliminar documento', 'OK', { duration: 6000, panelClass: ['error-snackbar'] });
                                this.eliminandoDocumentoId = undefined;
                            }
                        );
                    }
                });
        }
    }

    actualizarListadoDocumentos(event) {
        this.cargarArchivos();
    }
}
