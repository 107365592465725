<div fxLayout="column">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly start" fxLayoutAlign.lt-md="center stretch" fxLayoutGap.lt-md="15px">
        <div fxFlex="45" fxFlex.lt-md fxLayoutAlign="center">
            <div fxFlex fxFlex.lt-md="95">
                <app-archivos-listado [esFuncionario]="false"></app-archivos-listado>
            </div>
        </div>

        <div fxFlex="45" fxFlex.lt-md fxLayoutAlign="center">
            <div fxFlex fxFlex.lt-md="95">
                <app-funcionarios-listado></app-funcionarios-listado>
            </div>
        </div>
    </div>
</div>
