<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly start" fxLayoutAlign.lt-md="start stretch" fxLayoutGap.lt-md="15px">
    <div fxFlex fxFlex.gt-sm="45" fxLayoutAlign="center center">
        <div fxFlex fxFlex.lt-md="95" fxLayout="column">
            <mat-progress-bar *ngIf="mostrarSpinnerUsuario" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card>
                <mat-card-header>
                    <mat-card-title>Actualizar cuenta</mat-card-title>
                </mat-card-header>

                <mat-divider></mat-divider>

                <mat-card-content>
                    <div *ngIf="!edicionUsuario" fxLayout="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                                <mat-list appearance="outline" fxFlex="48">
                                    <h3 mat-subheader>Nombre</h3>
                                    <mat-list-item class="nombre">
                                        <h4 mat-line>{{ usuario?.nombre }}</h4>
                                    </mat-list-item>
                                </mat-list>

                                <mat-list appearance="outline" fxFlex="48">
                                    <h3 mat-subheader>Cédula</h3>
                                    <mat-list-item>
                                        <h4 mat-line>{{ usuario?.documento }}</h4>
                                    </mat-list-item>
                                </mat-list>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                                <mat-list appearance="outline" fxFlex="48">
                                    <h3 mat-subheader>Email</h3>
                                    <mat-list-item>
                                        <h4 mat-line>{{ usuario?.email }}</h4>
                                    </mat-list-item>
                                </mat-list>

                                <mat-list appearance="outline" fxFlex="48">
                                    <h3 mat-subheader>Teléfono</h3>
                                    <mat-list-item>
                                        <h4 mat-line>{{ usuario?.celular }}</h4>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center">
                            <button mat-flat-button color="primary" (click)="edicionUsuario = true">Editar</button>
                        </div>
                    </div>

                    <form [formGroup]="modificarUsuarioForm" (ngSubmit)="editarUsuario()" fxLayout="column" *ngIf="edicionUsuario">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-md="start stretch">
                            <div fxFlex fxLayout="row" fxFlex.gt-sm="48" fxLayoutAlign.lt-md="center center">
                                <mat-form-field appearance="outline" fxFlex fxFlex.lt-md="80">
                                    <mat-label>Nombre</mat-label>
                                    <input required matInput type="text" formControlName="nombre" placeholder="Nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
                                    <mat-error *ngIf="submitted && f.nombre.errors"> Nombre no puede estar vacío. </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex fxLayout="row" fxFlex.gt-sm="48" fxLayoutAlign.lt-md="center center">
                                <mat-form-field appearance="outline" fxFlex fxFlex.lt-md="80">
                                    <mat-label>Cédula</mat-label>
                                    <input required matInput type="text" formControlName="documento" placeholder="Cédula" [ngClass]="{ 'is-invalid': submitted && f.documento.errors }" />
                                    <mat-error *ngIf="submitted && f.documento.errors && f.documento.errors.required"> Cédula no puede estar vacío. </mat-error>
                                    <mat-error *ngIf="submitted && f.documento.errors?.largoIncorrecto"> Cédula es inválida. </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-md="start stretch">
                            <div fxFlex fxLayout="row" fxFlex.gt-sm="48" fxLayoutAlign.lt-md="center center">
                                <mat-form-field appearance="outline" fxFlex fxFlex.lt-md="80">
                                    <mat-label>Email</mat-label>
                                    <input required matInput type="email" formControlName="email" placeholder="Email" />
                                    <mat-error *ngIf="submitted && f.email.errors && f.email.errors.required"> Email no puede estar vacío. </mat-error>
                                    <mat-error *ngIf="submitted && f.email.errors && f.email.errors.email"> Email es inválido. </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex fxLayout="row" fxFlex.gt-sm="48" fxLayoutAlign.lt-md="center center">
                                <mat-form-field appearance="outline" fxFlex fxFlex.lt-md="80">
                                    <mat-label>Teléfono</mat-label>
                                    <input matInput type="text" formControlName="celular" placeholder="Teléfono" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                            <button mat-button type="button" (click)="edicionUsuario = false" [disabled]="mostrarSpinnerUsuario">Cancelar</button>
                            <button mat-flat-button color="primary" type="submit" [disabled]="mostrarSpinnerUsuario">Guardar</button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div fxFlex fxFlex.gt-sm="45" fxLayoutAlign="center center">
        <div fxFlex fxFlex.lt-md="95" fxLayout="column">
            <mat-progress-bar *ngIf="mostrarSpinnerContrasena" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card [class.cargando-contrasena]="mostrarSpinnerContrasena" fxLayoutGap="15px">
                <mat-card-header>
                    <mat-card-title>Cambiar contraseña</mat-card-title>
                </mat-card-header>

                <mat-divider></mat-divider>

                <mat-card-content>
                    <form [formGroup]="cambiarContrasenaForm" (ngSubmit)="editarContrasena()" fxLayout="column" fxLayoutGap="15px">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-md="start stretch">
                            <div fxFlex fxFlex.gt-sm="40" fxLayout="row" fxLayoutAlign.lt-md="center center">
                                <mat-form-field appearance="outline" fxFlex fxFlex.lt-md="80">
                                    <mat-label>Contraseña {{ cambiarContrasena ? 'nueva' : 'actual' }}</mat-label>
                                    <input matInput minlength="8" type="password" formControlName="contrasena" placeholder="Contraseña" />

                                    <mat-hint>Debe contener 8 digitos</mat-hint>
                                </mat-form-field>
                            </div>

                            <div fxFlex fxFlex.gt-sm="40" fxLayout="row" fxLayoutAlign.lt-md="center center">
                                <mat-form-field appearance="outline" fxFlex fxFlex.lt-md="80" [fxHide]="!cambiarContrasena">
                                    <mat-label>Confirmar contraseña</mat-label>
                                    <input matInput minlength="8" type="password" formControlName="repetir_contrasena" placeholder="Confirmar contraseña" />

                                    <mat-hint>Debe coincidir con la contraseña</mat-hint>
                                    <mat-error *ngIf="submitted && p.repetir_contrasena?.errors?.MatchPassword"> Contraseña no coincide. </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div *ngIf="!cambiarContrasena" fxLayoutAlign="center center">
                            <button mat-flat-button color="primary" type="submit" [disabled]="mostrarSpinnerContrasena">Verificar</button>
                        </div>

                        <div *ngIf="cambiarContrasena" fxLayoutAlign="center center" fxLayoutGap="5px">
                            <button mat-button type="button" (click)="cambiarContrasena = false" [disabled]="mostrarSpinnerContrasena">Cancelar</button>
                            <button mat-flat-button color="primary" type="submit" [disabled]="mostrarSpinnerContrasena">Cambiar</button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
