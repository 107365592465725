<div fxFlexFill fxLayout="column" fxLayoutAlign="space-around center">
    <mat-card *ngIf="!cargando" class="confirmar-card" fxLayout="column" fxLayoutAlign="start center">
        <mat-card-header>
            <mat-card-title>{{ titulo }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <h2>{{ mensaje }}</h2>
        </mat-card-content>

        <mat-divider></mat-divider>

        <p>Para volver a la página principal:</p>
        <button mat-flat-button type="submit" color="primary" [routerLink]="['/login']">Iniciar sesión</button>
    </mat-card>

    <mat-spinner *ngIf="cargando"></mat-spinner>

    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />
</div>
