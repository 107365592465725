<div fxLayout="row" fxLayoutAlign="space-between start" class="registrar-empresa">
    <button mat-raised-button *ngIf="!mostrarRegistroEmpresa" (click)="mostrarRegistroEmpresa = !mostrarRegistroEmpresa">
        <mat-icon class="espaciado-icono-boton">add</mat-icon>
        Registrar empresa
    </button>
</div>

<div *ngIf="mostrarRegistroEmpresa" fxFlex="40" fxFlex.lt-md="95" fxLayoutAlign="center center">
    <mat-card fxFlex fxLayoutGap="20px">
        <mat-card-header>
            <mat-card-title>Registrar empresa</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="registroForm" (ngSubmit)="crearEmpresa()" fxLayout.lt-md="column" fxLayoutGap="15px">
                <div fxLayout="column" fxLayoutAlign="start stretch">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field appearance="outline" fxFlex="80">
                            <mat-label>Razón Social</mat-label>
                            <input matInput formControlName="razon_social" [ngClass]="{ 'is-invalid': submitted }" required />
                            <mat-error *ngIf="submitted && f.razon_social.errors && f.razon_social.errors.required">Razon social es requerida</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field appearance="outline" fxFlex="80">
                            <mat-label>Nombre Comercial</mat-label>
                            <input matInput formControlName="nombre" [ngClass]="{ 'is-invalid': submitted }" />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field appearance="outline" fxFlex="80">
                            <mat-label>RUT</mat-label>
                            <input matInput formControlName="rut" maxlength="12" [ngClass]="{ 'is-invalid': submitted && f.rut.errors }" required />

                            <mat-error *ngIf="submitted && f.rut.errors && f.rut.errors.required">RUT es requerido</mat-error>
                            <mat-error *ngIf="submitted && f.rut.errors && f.rut.errors.rutInvalido">RUT ingresado es invalido</mat-error>
                            <mat-hint align="end">{{ f.rut.value.length }} / 12</mat-hint>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="column" fxFlex="80">
                            <mat-label>Subir Logo</mat-label>
                            <br />
                            <input #fileInput type="file" (change)="onChange($event)" />
                        </div>
                    </div>
                </div>

                <div fxLayoutAlign="end center" fxLayoutGap="5px">
                    <button [disabled]="mostrarSpinner" mat-button (click)="initForm()">Cancelar</button>
                    <button [disabled]="mostrarSpinner" mat-flat-button type="submit" color="primary">Registrar</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
