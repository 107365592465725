import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import decode from 'jwt-decode';

import { AuthService } from './auth.service';

@Injectable()
export class RoleUsuarioService implements CanActivate {
    constructor(public auth: AuthService, public router: Router, private snackBar: MatSnackBar) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedRole = route.data.expectedRole;
        const token = localStorage.getItem('token');

        if (!token || !this.auth.isAuthenticated()) {
            this.router.navigateByUrl('/login');
        }

        if (!expectedRole) {
            return true;
        }

        const tokenPayload: any = decode(token);
        localStorage.setItem('rol', tokenPayload.rol);

        if (expectedRole.every((e) => tokenPayload.rol !== e)) {
            this.snackBar.open('La ruta especificada no existe o no tiene permisos', 'OK', { panelClass: ['error-snackbar'] });
            return false;
        }

        return true;
    }
}
