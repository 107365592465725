import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ciPipe',
})
export class CiPipe implements PipeTransform {
    transform(value: number): String {
        if (!value || value.toString().length !== 8) {
            return undefined;
        }
        return (
            value.toString().slice(0, 1) + '.' + value.toString().slice(1, 4) + '.' + value.toString().slice(4, 7) + '-' + value.toString().slice(7)
        );
    }
}
