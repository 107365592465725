<h3>Luis Perez</h3>
<mat-nav-list>
    <a href="https://keep.google.com/" mat-list-item (click)="openLink($event)">
        <span mat-line>Diciembre-Enero</span>
        <mat-icon>check</mat-icon>
    </a>

    <a href="https://docs.google.com/" mat-list-item (click)="openLink($event)">
        <span mat-line>Febrero-Marzo</span>
    </a>

    <a href="https://plus.google.com/" mat-list-item (click)="openLink($event)">
        <span mat-line>Abril-Mayo</span>
    </a>

    <a href="https://hangouts.google.com/" mat-list-item (click)="openLink($event)">
        <span mat-line>Junio-Julio</span>
    </a>
</mat-nav-list>
