import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuarios } from '../models/usuarios';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RegistrationForm } from '../models/registrationForm';
import { UsuariosEmpresas } from '../models/usuariosEmpresas';
import { environment } from 'src/environments/environment';
import { ResetearContrasenaForm } from '../models/resetearContrasenaForm';

@Injectable()
export class UsuariosService {
    constructor(private _httpService: HttpClient) {}

    confirmarCuenta(codigo: String) {
        return this._httpService.post<string>(environment.WEB_API_URL + 'usuarios/confirmar', { codigo: codigo });
    }

    getUsuario(): Observable<Usuarios> {
        return this._httpService.get<Usuarios>(environment.WEB_API_URL + 'usuarios/ver', this.jwt());
    }

    getUsuariosEmpresa(id: String | number): Observable<UsuariosEmpresas[]> {
        return this._httpService.get<UsuariosEmpresas[]>(environment.WEB_API_URL + 'usuarios/empresa' + (id ? '/' + id : ''), this.jwt());
    }

    getAdministradores(): Observable<Usuarios[]> {
        return this._httpService.get<Usuarios[]>(environment.WEB_API_URL + 'usuarios/administradores', this.jwt());
    }

    getInvitados(): Observable<Usuarios[]> {
        return this._httpService.get<Usuarios[]>(environment.WEB_API_URL + 'usuarios/invitados', this.jwt());
    }

    editar(usuario: Usuarios): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'usuarios/editar', { Usuarios: usuario }, this.jwt());
    }

    editarContrasena(usuario: Usuarios): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'usuarios/editar-contrasena', {
            Usuarios: usuario,
        });
    }

    invitar(usuario: Usuarios): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'usuarios/invitar', { Registro: usuario }, this.jwt());
    }

    reenviarInvitacion(form: ResetearContrasenaForm) {
        return this._httpService.post<string>(environment.WEB_API_URL + 'usuarios/reenviar-invitacion', { UsuariosInvitaciones: form }, this.jwt());
    }

    verificarContrasena(contrasena: String): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'usuarios/validar-contrasena', { contrasena: contrasena }, this.jwt());
    }

    cambiarContrasena(regForm: RegistrationForm): Observable<String> {
        return this._httpService.post<String>(environment.WEB_API_URL + 'usuarios/cambiar-contrasena', { Registro: regForm }, this.jwt());
    }

    eliminar(usuEmpresas: UsuariosEmpresas): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'usuarios/eliminar', { UsuariosEmpresas: usuEmpresas }, this.jwt());
    }

    resetearContrasena(resetearContrasena: ResetearContrasenaForm) {
        return this._httpService.post<string>(environment.WEB_API_URL + 'usuarios/resetear-contrasena', { ReseteoContrasena: resetearContrasena });
    }

    private jwt() {
        const token = localStorage.getItem('token');
        if (token) {
            const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
            return { headers: headers };
        }
    }
}
