<div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
    <div fxLayout="row">
        <app-empresas-registrar fxLayoutAlign="center center" fxFlex (actualizarListado)="cargarEmpresas()"></app-empresas-registrar>
    </div>

    <div fxLayout="row wrap" fxLayout.lt-md="column" class="grilla" fxLayoutAlign="center center" fxLayoutAlign.lt-md="start stretch" fxLayoutGap="15px">
        <div fxFlex.gt-sm="330px" fxLayoutAlign.lt-md="center center" *ngFor="let empresa of empresas" class="empresa-card-container">
            <mat-card fxFlex.lt-md="90" fxLayoutGap="10px">
                <img class="logo-empresa" *ngIf="empresa.archivo0?.url" [src]="empresa.archivo0?.url" />

                <mat-icon class="logo-empresa sin-logo" *ngIf="!empresa.archivo0?.url" matPrefix>business</mat-icon>

                <mat-card-title>{{ empresa.razon_social }}</mat-card-title>

                <mat-card-subtitle> {{ empresa.rut }}</mat-card-subtitle>

                <div fxShow.gt-sm fxLayout="row" *ngIf="empresa.esAdmin" class="avatar-container">
                    <ngx-avatar *ngFor="let funcionario of empresa.funcionarios" [matTooltip]="funcionario.nombre" size="30" [value]="funcionario.iniciales"> </ngx-avatar>
                    <p *ngIf="empresa.cantidadFuncionarios > 5" matTooltip="+{{ empresa.cantidadFuncionarios - 5 }}">...</p>
                </div>

                <div *ngIf="!cargando && empresa.esAdmin">
                    <mat-divider></mat-divider>

                    <p>Ingresar como:</p>

                    <div fxLayoutAlign="center">
                        <button mat-stroked-button color="primary" style="margin-right: 30px" (click)="seleccionar(empresa, 'admin_empresa')">Administrador</button>
                        <button mat-stroked-button color="primary" (click)="seleccionar(empresa, 'funcionario')">Funcionario</button>
                    </div>
                </div>

                <div *ngIf="!cargando && !empresa.esAdmin">
                    <mat-divider></mat-divider>

                    <div class="botones-acceso" fxLayoutAlign="end">
                        <button mat-stroked-button color="primary" (click)="seleccionar(empresa, 'funcionario')">Ingresar</button>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
