import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-site-verificar',
    templateUrl: './verificar.component.html',
    styleUrls: ['./verificar.component.scss'],
})
export class VerificarComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
