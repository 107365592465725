<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px">
    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />

    <div fxLayoutAlign="center start">
        <div fxLayout="column" fxFlex="30" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="90">
            <mat-progress-bar *ngIf="mostrarSpinner" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card class="tarjeta-login" [class.spinner]="mostrarSpinner" *ngIf="!verificarContrasena">
                <mat-card-header>
                    <mat-card-title>Solicitar reseteo</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="reseteoForm" (ngSubmit)="solicitarReseteo()" fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Email o Cédula</mat-label>
                            <input required matInput type="usuario" formControlName="usuario" [ngClass]="{ 'is-invalid': submitted && f.usuario.errors }" />
                        </mat-form-field>
                        <mat-error id="formError" style="display: none"></mat-error>

                        <mat-card-actions fxLayout="column" fxLayoutAlign="start center">
                            <div fxLayout="column" fxLayoutAlign="space-between center">
                                <button [disabled]="mostrarSpinner" mat-flat-button color="primary">Solicitar</button>
                                <button mat-button class="iniciar-sesion" [routerLink]="['/login']">Iniciar sesión</button>
                            </div>
                        </mat-card-actions>
                    </form>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-footer fxLayout="column" fxLayoutAlign="start center">
                    <p class="textoGris">¿No tenés cuenta?</p>
                    <a mat-flat-button color="link" [routerLink]="['/registro']">Registrarse</a>
                </mat-card-footer>
            </mat-card>

            <mat-card class="tarjeta-login" [class.spinner]="mostrarSpinner" *ngIf="verificarContrasena">
                <mat-card-header fxLayoutAlign="center center">
                    <mat-card-title>¡LISTO!</mat-card-title>
                </mat-card-header>

                <mat-card-content fxLayoutAlign="center center">
                    <p>Se envió un correo a la dirección indicada con instrucciones para resetear tu contraseña.</p>
                </mat-card-content>

                <mat-card-footer fxLayout="column" fxLayoutAlign="start center">
                    <p class="textoGris">Para volver a la página principal:</p>
                    <a mat-flat-button color="primary" [routerLink]="['/login']">Iniciar sesión</a>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</div>
