import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SiteService } from 'src/app/services';
import { ValidadorCi, PasswordValidation } from 'src/app/shared';

@Component({
    selector: 'app-registro',
    templateUrl: './registro.component.html',
    styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
    registroForm: FormGroup;

    mensajeError;

    submitted = false;
    mostrarSpinner = false;

    constructor(private formBuilder: FormBuilder, private _siteService: SiteService, private router: Router) {}

    ngOnInit() {
        this.registroForm = this.formBuilder.group(
            {
                nombre: ['', Validators.required],
                celular: [''],
                documento: ['', [Validators.required]],
                email: ['', [Validators.required, Validators.email]],
                contrasena: ['', Validators.required],
                repetir_contrasena: ['', Validators.required],
            },
            {
                validator: [PasswordValidation.MatchPassword, ValidadorCi()],
            }
        );
    }
    get f() {
        return this.registroForm.controls;
    }

    crearUsuario() {
        if (this.registroForm.invalid) {
            return;
        }

        this.mostrarSpinner = true;
        this.submitted = true;

        this._siteService.registrar(this.registroForm.value).subscribe(
            (response) => {
                this.submitted = false;
                this.mostrarSpinner = false;
                this.router.navigateByUrl('/site/verificar');
            },
            (error: any) => {
                this.submitted = false;
                this.mostrarSpinner = false;
                document.getElementById('formError').style.display = 'initial';
                document.getElementById('formError').innerHTML = error.error?.message || 'Error al registrar usuario';
            }
        );
    }
}
