import { FormGroup } from '@angular/forms';

export function ValidadorEmailCelular() {
    return (formGroup: FormGroup) => {
        const email = formGroup.controls['email'];
        const celular = formGroup.controls['celular'];

        if (!email.value && !celular.value) {
            email.setErrors({ ValidadorEmailCelular: true });
            celular.setErrors({ ValidadorEmailCelular: true });
        } else {
            email.setErrors(null);
            celular.setErrors(null);
        }
    };
}
