<div fxLayout="column" fxLayoutGap="15px">
    <app-archivos-subir *ngIf="esAdmin" [esFuncionario]="esFuncionario"></app-archivos-subir>

    <div fxLayout="column" fxLayoutAlign="start stretch">
        <mat-progress-bar *ngIf="cargandoArchivos" mode="indeterminate" color="primary"> </mat-progress-bar>

        <mat-card class="documentos-laborales" [class.cargando-archivos]="cargandoArchivos">
            <mat-card-header>
                <mat-card-title>Documentos Laborales</mat-card-title>
                <mat-card-subtitle *ngIf="!esFuncionario">Se muestran archivos originales y cargas individuales</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
                <mat-form-field class="search-field">
                    <span matPrefix> <mat-icon>search</mat-icon> &nbsp;</span>
                    <input autocomplete="off" matInput [(ngModel)]="campoBusqueda" (keyup)="cargarArchivos()" />
                </mat-form-field>

                <mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="subido_por">
                        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef>Subido por</mat-header-cell>
                        <mat-cell *matCellDef="let documento">
                            {{ documento.subidoPor.nombre }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="archivo">
                        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef>Documento</mat-header-cell>
                        <mat-cell *matCellDef="let documento">
                            {{ documento.archivo0.nombre_origen }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fecha_creacion">
                        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef>Fecha carga</mat-header-cell>
                        <mat-cell fxLayoutAlign="center center" *matCellDef="let documento">
                            {{ documento.fecha_creacion.replace(' ', 'T') | date: 'd/M/Y, H:mm' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="visto">
                        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef>Visto</mat-header-cell>
                        <mat-cell fxLayoutAlign="center center" *matCellDef="let documento">
                            {{ documento.fecha_aceptado ? (documento.fecha_aceptado.replace(' ', 'T') | date: 'd/M/Y, H:mm') : 'Pendiente' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mas">
                        <mat-header-cell fxFlex="5" fxLayoutAlign="center center" *matHeaderCellDef></mat-header-cell>
                        <mat-cell
                            fxFlex="5"
                            fxLayoutAlign="center center"
                            *matCellDef="let documento"
                            [matMenuTriggerFor]="documentoMenu"
                            [matMenuTriggerData]="{ documento: documento }"
                            (click)="$event.stopPropagation()"
                        >
                            <button mat-icon-button *ngIf="eliminandoDocumentoId != documento.id"><mat-icon>more_vert</mat-icon></button>

                            <mat-icon *ngIf="eliminandoDocumentoId == documento.id">
                                <mat-spinner color="primary" diameter="20"></mat-spinner>
                            </mat-icon>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="columnasAMostrar"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnasAMostrar" (click)="abrirDocumento(row)"></mat-row>
                </mat-table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="cambiarPagina()" [fxHide]="!dataSource?.filteredData.length"></mat-paginator>

                <p class="no-hay-resultados" *ngIf="!dataSource?.filteredData.length">No hay información disponible.</p>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<mat-menu #documentoMenu="matMenu" xPosition="after">
    <ng-template matMenuContent let-documento="documento">
        <button mat-menu-item (click)="eliminarDocumento(documento)">
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
        </button>
    </ng-template>
</mat-menu>
