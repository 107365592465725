import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable()
export class AuthService {
    private jwtHelper = new JwtHelperService();

    constructor(private router: Router, private snackBar: MatSnackBar) {}

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');

        if (!token) {
            this.router.navigateByUrl('/login');
        }

        if (this.jwtHelper.isTokenExpired(token)) {
            this.snackBar.open('La sesión expiró', 'OK', { duration: 4000, panelClass: ['info-snackbar'] });
            this.router.navigateByUrl('/login');
        }

        return true;
    }
}
