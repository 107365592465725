import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './components/layouts/main/main.component';
import { EmpresasListadoComponent } from './components/empresas/listado/listado.component';
import { FuncionarioComponent } from './components/funcionarios/index/funcionario.component';
import { EmpresaComponent } from './components/empresas/index/empresa.component';
import { LoginComponent } from './components/site/login/index/index.component';
import { RegistroComponent } from './components/site/registro/registro.component';
import { UsuariosEditarComponent } from './components/usuarios/editar/editar.component';
import { VerificarComponent } from './components/site/verificar/verificar.component';
import { EmpresasEditarComponent } from './components/empresas/editar/editar.component';
import { ReseteoContrasenaComponent } from './components/site/login/reseteo-contrasena/reseteo-contrasena.component';
import { ResetearContrasenaComponent } from './components/site/resetear-contrasena/resetear-contrasena.component';
import { RoleUsuarioService as RoleUsuario } from './auth/role-usuario.service';
import { RoleUsuarioEmpresaService as RoleUsuarioEmpresa } from './auth/role-usuario-empresa';
import { InvitacionComponent } from './components/site/invitacion/invitacion.component';
import { ConfirmarCuentaComponent } from './components/site/confirmar-cuenta/confirmar-cuenta.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/empresas', pathMatch: 'full' },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: 'empresas',
                component: EmpresasListadoComponent,
                canActivate: [RoleUsuario],
            },
            {
                path: 'usuarios/editar',
                component: UsuariosEditarComponent,
                canActivate: [RoleUsuario],
            },
            {
                path: 'empresas/editar',
                component: EmpresasEditarComponent,
                canActivate: [RoleUsuarioEmpresa],
                data: { expectedRole: ['admin_empresa'] },
            },
            {
                path: 'empresas/:id',
                component: EmpresaComponent,
                canActivate: [RoleUsuarioEmpresa],
                data: { expectedRole: ['admin_empresa'] },
            },
            {
                path: 'funcionarios/:id',
                component: FuncionarioComponent,
                canActivate: [RoleUsuarioEmpresa],
                data: { expectedRole: ['admin_empresa', 'funcionario'] },
            },
        ],
    },
    { path: 'login', component: LoginComponent },
    { path: 'registro', component: RegistroComponent },
    { path: 'resetear-contrasena/:codigo', component: ResetearContrasenaComponent },
    { path: 'usuarios/aceptar-invitacion', component: InvitacionComponent },
    { path: 'usuarios/confirmar', component: ConfirmarCuentaComponent },
    { path: 'site/verificar', component: VerificarComponent },
    { path: 'reseteo-contrasena', component: ReseteoContrasenaComponent },
];

export const routing = RouterModule.forRoot(appRoutes);
