<mat-card class="tarjeta-funcionarios">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-header class="titulo-tarjeta">
            <mat-card-title style="padding-bottom: 15px">Funcionarios</mat-card-title>
        </mat-card-header>

        <div class="funcionarios-actions">
            <button mat-button (click)="mostrarRegistroFuncionario = !mostrarRegistroFuncionario">
                <mat-icon matToolTip="Invitar usuario">person_add</mat-icon>
            </button>

            <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>build</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <form [formGroup]="mostrarFuncionariosForm">
                    <button mat-menu-item type="button" disableRipple (click)="$event.stopPropagation()">
                        <mat-checkbox formControlName="inactivos">Mostrar inactivos</mat-checkbox>
                    </button>
                    <button mat-menu-item type="button" disableRipple (click)="$event.stopPropagation()">
                        <mat-checkbox formControlName="pendientes">Mostrar pendientes</mat-checkbox>
                    </button>
                </form>
            </mat-menu>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div *ngIf="mostrarRegistroFuncionario" class="registrar-funcionario-div">
        <mat-card-header>
            <mat-card-title>Registrar nuevo</mat-card-title>
        </mat-card-header>

        <form fxLayout="column" [formGroup]="registroForm" (ngSubmit)="crearFuncionario()" style="padding: 10px 0">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-md="start stretch">
                <mat-form-field appearance="outline" fxFlex="45">
                    <mat-label>Nombre</mat-label>
                    <input required matInput formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
                    <mat-error *ngIf="submitted && f.nombre.errors">Nombre no puede estar vacío.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="45">
                    <mat-label>Teléfono</mat-label>
                    <input matInput formControlName="celular" [ngClass]="{ 'is-invalid': submitted && f.celular.errors }" />
                    <mat-error *ngIf="submitted && f.celular.errors?.ValidadorEmailCelular">Debe ingresar Email o Teléfono.</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-md="start stretch">
                <mat-form-field appearance="outline" fxFlex="45">
                    <mat-label>Cédula</mat-label>
                    <input required matInput formControlName="documento" [ngClass]="{ 'is-invalid': submitted && f.documento.errors }" />
                    <mat-error *ngIf="submitted && f.documento.errors?.required">Cédula no puede estar vacío.</mat-error>
                    <mat-error *ngIf="submitted && f.documento.errors?.largoIncorrecto">Cédula es inválido.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="45">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <mat-error *ngIf="submitted && f.email.errors?.email">Email es inválido.</mat-error>
                    <mat-error *ngIf="submitted && f.email.errors?.ValidadorEmailCelular">Debe ingresar Email o Teléfono.</mat-error>
                </mat-form-field>
            </div>

            <mat-error *ngFor="let error of mensajesError" class="mensaje-error"> {{ error }}</mat-error>

            <div class="cancelar-registrar" fxLayoutAlign="end" fxLayoutGap="10px">
                <button [disabled]="mostrarSpinner" mat-button type="button" (click)="mostrarRegistroFuncionario = false; initForm()">Cancelar</button>
                <button [disabled]="mostrarSpinner" mat-flat-button type="submit" color="primary">Registrar</button>
                <mat-spinner *ngIf="mostrarSpinner" [diameter]="30"></mat-spinner>
            </div>
        </form>

        <mat-divider></mat-divider>
    </div>

    <mat-action-list>
        <mat-form-field class="espaciado-buscador">
            <span matPrefix> <mat-icon>search</mat-icon> &nbsp;</span>
            <input autocomplete="off" matInput [(ngModel)]="campoBusqueda" (keyup)="cargarFuncionarios()" ngDefaultControl />
        </mat-form-field>

        <div class="listado-container" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let funcionario of funcionarios">
            <ngx-avatar size="30" class="avatar-funcionario" value="{{ funcionario.iniciales }}"> </ngx-avatar>

            <mat-list-item [routerLink]="irFuncionario(funcionario)">
                <h4 mat-line>{{ funcionario.nombre }}</h4>
                <p mat-line>{{ funcionario.documento | ciPipe }}</p>
                <p mat-line>{{ funcionario.celular }}</p>
            </mat-list-item>

            <button *ngIf="!cargandoFuncionarios && esTablaPendientes" mat-icon-button (click)="invitar(funcionario)" matTooltip="Reenviar invitación">
                <mat-icon>email</mat-icon>
            </button>

            <div *ngFor="let documento of funcionario.documentos">
                <a (click)="abrirDocumento(documento)">
                    <button mat-icon-button [class.icono-leido]="documento.fecha_aceptado" color="primary" [matTooltip]="tooltipDocumento(documento)" matTooltipClass="app-tooltip">
                        <mat-icon>insert_drive_file</mat-icon>
                    </button>
                </a>
            </div>

            <button mat-icon-button matTooltip="Subir documento" class="boton-subir" mat-button color="primary" (click)="fileInput.click()">
                <mat-icon>publish</mat-icon>
            </button>

            <input #fileInput (change)="fileChange($event, funcionario.id)" type="file" hidden />
        </div>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="cambiarPagina()" [fxHide]="!funcionarios?.length"></mat-paginator>

        <p class="no-hay-resultados" *ngIf="!funcionarios?.length">No hay información disponible.</p>
    </mat-action-list>
</mat-card>
