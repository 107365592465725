<mat-toolbar color="primary">
    <mat-toolbar-row fxLayoutAlign="space-between center">
        <span>
            <a [routerLink]="['/empresas']">
                <img class="logo" src="assets/logos/logo_biller_unatinta_blanco.svg" alt="Biller" />
            </a>
        </span>

        <div fxLayout="row" fxLayoutGap="10px">
            <div fxLayout="column" fxLayoutAlign="space-around end">
                <div class="nombre-empresa">{{ nombreEmpresa }}</div>
                <div class="nombre-usuario">{{ nombreUsuario }}</div>
            </div>

            <button mat-icon-button matTooltip="Mi&nbsp;cuenta" [matMenuTriggerFor]="menu">
                <mat-icon>account_circle</mat-icon>
            </button>
        </div>

        <mat-menu #menu="matMenu">
            <button mat-menu-item class="ir-perfil" [routerLink]="['/usuarios/editar']"><mat-icon>person</mat-icon>Perfil</button>
            <button mat-menu-item class="ir-perfil-empresa" [routerLink]="['/empresas/editar']" [fxHide]="!empresaSeleccionada"><mat-icon>settings</mat-icon>Perfil empresa</button>
            <button mat-menu-item class="seleccionar-empresa" [routerLink]="['/empresas']"><mat-icon>compare_arrows</mat-icon>Cambiar de empresa</button>
            <button mat-menu-item class="salir" [routerLink]="['/login']"><mat-icon>exit_to_app</mat-icon>Salir</button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>
