<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly start" fxLayoutAlign.lt-md="start stretch" fxLayoutGap.lt-md="15px">
    <div fxFlex fxFlex.gt-sm="45" fxLayout="row" fxLayoutAlign="center center">
        <mat-card fxFlex fxFlex.lt-md="95">
            <mat-card-header>
                <mat-card-title>Actualizar empresa</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <mat-divider></mat-divider>

                <div *ngIf="!edicionEmpresa" fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                        <mat-list appearance="outline" fxFlex="48">
                            <h3 mat-subheader>Razón Social</h3>
                            <mat-list-item>
                                <h4 mat-line>{{ empresa?.razon_social }}</h4>
                            </mat-list-item>
                        </mat-list>

                        <mat-list appearance="outline" fxFlex="48">
                            <h3 mat-subheader>Nombre Comercial</h3>
                            <mat-list-item>
                                <h4 mat-line>{{ empresa?.nombre }}</h4>
                            </mat-list-item>
                        </mat-list>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                        <mat-list appearance="outline" fxFlex="48">
                            <h3 mat-subheader>RUT</h3>
                            <mat-list-item>
                                <h4 mat-line>{{ empresa?.rut }}</h4>
                            </mat-list-item>
                        </mat-list>

                        <mat-list appearance="outline" fxFlex="48">
                            <h3 mat-subheader>Logo Empresa</h3>
                            <mat-list-item>
                                <h4 mat-line>{{ empresa?.archivo0 ? empresa?.archivo0?.nombre_origen : ' - ' }}</h4>
                            </mat-list-item>
                        </mat-list>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <button mat-flat-button color="primary" (click)="edicionEmpresa = true">Editar</button>
                    </div>
                </div>

                <form [formGroup]="modificarEmpresaForm" (ngSubmit)="editarEmpresa()" fxLayout="column" *ngIf="edicionEmpresa" style="margin-top: 10px">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
                        <mat-form-field appearance="outline" fxFlex="48">
                            <mat-label>Razón Social</mat-label>
                            <input required matInput type="text" formControlName="razon_social" placeholder="Razon Social" [ngClass]="{ 'is-invalid': submitted && f.razon_social.errors }" />
                            <mat-error *ngIf="submitted && f.razon_social.errors"> Nombre no puede estar vacío. </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="48">
                            <mat-label>Nombre Comercial</mat-label>
                            <input required matInput type="text" formControlName="nombre" placeholder="Nombre Comercial" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
                            <mat-error *ngIf="submitted && f.nombre.errors && f.nombre.errors.required"> Cédula no puede estar vacío. </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
                        <mat-form-field appearance="outline" fxFlex="48">
                            <mat-label>RUT</mat-label>
                            <input required matInput type="text" formControlName="rut" placeholder="RUT" />
                            <mat-error *ngIf="submitted && f.rut.errors && f.rut.errors.required"> Email no puede estar vacío. </mat-error>
                        </mat-form-field>

                        <div appearance="outline" fxFlex="46">
                            <mat-label>Logo Empresa</mat-label>
                            <br />
                            <input style="margin-top: 10px; margin-bottom: 20px" id="myForm" type="file" (change)="onChange($event)" />
                        </div>
                    </div>

                    <mat-error *ngIf="mensajeError">{{ mensajeError }}</mat-error>

                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <button mat-button type="button" (click)="edicionEmpresa = false; mensajeError = ''">Cancelar</button>
                        <button mat-flat-button type="submit" color="primary">Guardar</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxFlex fxFlex.gt-sm="45" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-progress-bar *ngIf="cargandoAdministradores" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card fxFlex fxFlex.lt-md="95" [class.cargando]="cargandoAdministradores">
                <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-card-title>Administradores </mat-card-title>

                    <button mat-button (click)="verInvitacion = !verInvitacion">
                        <mat-icon title="Agregar usuario">person_add</mat-icon>
                    </button>
                </mat-card-header>

                <mat-card-content *ngIf="verInvitacion">
                    <mat-divider></mat-divider>

                    <form fxLayout="column" [formGroup]="invitacionForm" (ngSubmit)="invitarUsuario()">
                        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column">
                            <mat-form-field appearance="outline" fxFlex="47">
                                <mat-label>Nombre</mat-label>
                                <input matInput formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && i.nombre.errors }" required />
                                <mat-error *ngIf="submitted && i.nombre.errors">Nombre no puede estar vacío.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="47">
                                <mat-label>Teléfono</mat-label>
                                <input matInput formControlName="celular" />
                                <mat-error *ngIf="submitted && i.celular.errors?.ValidadorEmailCelular">Debe ingresar Email o Teléfono.</mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column">
                            <mat-form-field appearance="outline" fxFlex="47">
                                <mat-label>Cédula</mat-label>
                                <input matInput formControlName="documento" [ngClass]="{ 'is-invalid': submitted && i.documento.errors }" required />
                                <mat-error *ngIf="submitted && i.documento.errors && i.documento.errors.required">Cédula no puede estar vacío.</mat-error>
                                <mat-error *ngIf="submitted && i.documento.errors && i.documento.errors.largoIncorrecto">Cédula es inválido.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="47">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" [ngClass]="{ 'is-invalid': submitted && i.email.errors }" />
                                <mat-error *ngIf="submitted && i.email.errors && i.email.errors.email">Email es inválido.</mat-error>
                                <mat-error *ngIf="submitted && i.email.errors?.ValidadorEmailCelular">Debe ingresar Email o Teléfono.</mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                            <button [disabled]="cargandoAdministradores" mat-button type="button" (click)="verInvitacion = false; resetCampos()">Cancelar</button>
                            <button [disabled]="cargandoAdministradores" mat-flat-button type="submit" color="primary">Invitar</button>
                        </div>
                    </form>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-content>
                    <mat-action-list>
                        <div fxLayoutAlign="space-between center" *ngFor="let usuario of administradores" class="elemento-lista-func">
                            <ngx-avatar size="30" class="avatar-funcionario" value="{{ usuario.iniciales }}"> </ngx-avatar>

                            <mat-list-item>
                                <h4 mat-line>{{ usuario.nombre }}</h4>
                                <p mat-line>{{ usuario.documento | ciPipe }}</p>
                                <p mat-line>{{ usuario.email }}</p>

                                <button mat-flat-button color="primary" (click)="eliminarAdmin(usuario)">Cambiar a funcionario</button>
                            </mat-list-item>
                        </div>
                    </mat-action-list>

                    <p class="no-hay-resultados" *ngIf="!administradores?.length">No hay información disponible.</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-progress-bar *ngIf="cargandoInvitaciones" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card fxFlex fxFlex.lt-md="95" [class.cargando]="cargandoInvitaciones">
                <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-card-title>Invitaciones pendientes</mat-card-title>
                </mat-card-header>

                <mat-divider></mat-divider>

                <mat-card-content>
                    <mat-action-list>
                        <div fxLayoutAlign="space-between center" *ngFor="let invitacion of invitaciones" class="elemento-lista-func">
                            <ngx-avatar size="30" class="avatar-funcionario" value="{{ invitacion.usuario0.iniciales }}"> </ngx-avatar>

                            <mat-list-item>
                                <h4 mat-line>{{ invitacion.usuario0.nombre }}</h4>
                                <p mat-line>{{ invitacion.usuario0.documento | ciPipe }}</p>
                                <p mat-line>{{ invitacion.usuario0.email }}</p>
                                <p mat-line>Rol: {{ getNombreRol(invitacion.rol) }}</p>

                                <button mat-flat-button color="primary" (click)="reenviar(invitacion.usuario0)" [disabled]="cargandoInvitaciones">Reenviar</button>
                            </mat-list-item>
                        </div>
                    </mat-action-list>

                    <p class="no-hay-resultados" *ngIf="!invitaciones?.length">No hay información disponible.</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
