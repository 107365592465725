import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Documentos, Empresas, UsuariosEmpresas } from 'src/app/models';
import { DocumentosService, EmpresasService, EventEmitterService, UsuariosService } from 'src/app/services';

@Component({
    selector: 'app-empresas-listado',
    templateUrl: './listado.component.html',
    styleUrls: ['./listado.component.scss'],
})
export class EmpresasListadoComponent implements OnInit {
    idUsuario: number;
    empresas: Empresas[];

    cargando = true;

    constructor(
        private _empresasService: EmpresasService,
        private _usuariosService: UsuariosService,
        private _documentosService: DocumentosService,
        private _eventEmitter: EventEmitterService,
        private _snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.idUsuario = parseInt(localStorage.getItem('idUsuario'));

        localStorage.removeItem('nombreEmpresa');
        this._eventEmitter.actualizarToolBar.emit(null);

        this._documentosService.notificacion().subscribe((documentos: Documentos[]) => {
            this.mostrarNotificaciones(documentos);
        });

        this.cargarEmpresas();
    }

    cargarEmpresas() {
        this.cargando = true;

        this._empresasService.getEmpresas().subscribe(
            (empresas: Empresas[]) => {
                this.empresas = empresas;
                this.procesarFuncionarios();
            },
            (error: any) => {
                this._snackBar.open('Error al cargar empresas', 'OK', { duration: 5000, panelClass: ['error-snackbar'] });
                this.cargando = false;
            }
        );
    }

    procesarFuncionarios() {
        this.empresas.forEach((empresa) => {
            empresa.esAdmin = empresa.usuariosEmpresas.some((ue) => ue.rol === 'admin_empresa');

            if (empresa.esAdmin) {
                this._usuariosService.getUsuariosEmpresa(empresa.id).subscribe(
                    (ue: UsuariosEmpresas[]) => {
                        empresa.funcionarios = this.procesarUsuariosEmpresas(ue.filter((u) => u.usuario != this.idUsuario).slice(0, 5));
                        empresa.cantidadFuncionarios = ue.length;
                        this.cargando = false;
                    },
                    (_) => (this.cargando = false)
                );
            } else {
                this.cargando = false;
            }
        });
    }

    procesarUsuariosEmpresas(usuariosEmpresas: UsuariosEmpresas[]) {
        return usuariosEmpresas.map((element) => {
            element.usuario0.iniciales = element.usuario0.nombre.match(/\b(\w)/g).join('');
            return element.usuario0;
        });
    }

    seleccionar(empresa: Empresas, rol: string) {
        this._empresasService.seleccionar(empresa.id, rol);
    }

    mostrarNotificaciones(documentos: Documentos[]) {
        if (documentos.length > 0) {
            this._snackBar.open('Hay archivos nuevos sin ver', 'OK', { duration: 5000, panelClass: ['info-snackbar'] });
        }
    }
}
