<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px">
    <img class="logo" src="assets/logos/logo_biller.svg" alt="Biller" />

    <div fxLayoutAlign="center start">
        <div fxLayout="column" fxFlex="30" fxFlex.md="40" fxFlex.sm="60" fxFlex.xs="90">
            <mat-progress-bar *ngIf="mostrarSpinner" mode="indeterminate" color="primary"> </mat-progress-bar>

            <mat-card class="tarjeta-login" [class.spinner]="mostrarSpinner">
                <mat-card-header>
                    <mat-card-title>Iniciar sesión</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="loginForm" (ngSubmit)="login()" fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Email o Cédula</mat-label>
                            <input required matInput type="email" formControlName="usuario" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.usuario.errors }" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input required matInput type="password" formControlName="contrasena" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.contrasena.errors }" />
                        </mat-form-field>

                        <div fxLayout="column" fxLayoutGap="15px">
                            <mat-error *ngIf="loginError">{{ loginError }}</mat-error>

                            <mat-card-actions>
                                <div fxLayout="column" fxLayoutAlign="space-between center">
                                    <button [disabled]="mostrarSpinner" mat-flat-button color="primary">Iniciar sesión</button>
                                    <button mat-button class="olvide-contrasena" [routerLink]="['/reseteo-contrasena']">Olvidé mi contraseña</button>
                                </div>
                            </mat-card-actions>
                        </div>
                    </form>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-footer fxLayout="column" fxLayoutAlign="start center">
                    <p>¿No tenés cuenta?</p>
                    <button mat-flat-button color="link" [routerLink]="['/registro']">Registrarse</button>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</div>
