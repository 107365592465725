import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class EventEmitterService {
    actualizarToolBar = new EventEmitter();
    actualizarDocumentos = new EventEmitter();
    actualizarFuncionarios = new EventEmitter();
    spinner = new EventEmitter();

    constructor() {}

    sendMessage(data: String) {
        this.actualizarToolBar.emit(data);
    }

    actualizarListadoDocumentos(data: String) {
        this.actualizarDocumentos.emit(data);
    }

    actualizarListadoFuncionarios(data: String) {
        this.actualizarFuncionarios.emit(data);
    }

    desactivarSpinner(data: String) {
        this.spinner.emit(data);
    }
}
