import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Usuarios, UsuariosEmpresas, ResetearContrasenaForm, SolicitarReseteoForm } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable()
export class FuncionariosService {
    usuarios: Usuarios;

    constructor(private _httpService: HttpClient) {}

    getFuncionarios(pagina = 1, limite = 10, filtro = '', activos = true, pendientes = false): Observable<any> {
        const params = new HttpParams()
            .set('page', pagina.toString())
            .set('pageSize', limite.toString())
            .set('filter', filtro)
            .set('activos', activos.toString())
            .set('pendientes', pendientes.toString());

        return this._httpService.get<any>(environment.WEB_API_URL + 'funcionarios?' + params.toString(), this.jwt());
    }

    getFuncionario(id: number): Observable<Usuarios> {
        return this._httpService.get<Usuarios>(environment.WEB_API_URL + 'funcionarios/' + id, this.jwt());
    }

    registrar(usuario: Usuarios): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'usuarios/invitar', { Registro: usuario }, this.jwt());
    }

    editar(idFuncionario: number, usuario: Usuarios): Observable<Usuarios> {
        return this._httpService.post<Usuarios>(environment.WEB_API_URL + 'funcionarios/editar/' + idFuncionario, { Usuarios: usuario }, this.jwt());
    }

    desactivar(id: number): Observable<Usuarios> {
        return this._httpService.get<Usuarios>(environment.WEB_API_URL + 'funcionarios/desactivar/' + id, this.jwt());
    }

    getDocumentos(id: number, page: number, pageSize: number, filter = ''): Observable<any> {
        const params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString()).set('filter', filter);

        return this._httpService.get<any>(environment.WEB_API_URL + 'funcionarios/documentos-laborales/' + id + '?' + params.toString(), this.jwt());
    }

    eliminar(id: number): Observable<Usuarios> {
        return this._httpService.get<Usuarios>(environment.WEB_API_URL + 'usuarios/eliminar/' + id, this.jwt());
    }

    resetearContrasena(form: SolicitarReseteoForm) {
        return this._httpService.post<string>(environment.WEB_API_URL + 'funcionarios/resetear-contrasena', form, this.jwt());
    }

    private jwt() {
        const token = localStorage.getItem('token');
        if (token) {
            const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
            return { headers: headers };
        }
    }
}
