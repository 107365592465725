import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-funcionarios-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss'],
})
export class FuncionariosBottomSheetComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    openLink(event) {}
}
